<template>

  <section class="main-body" v-if="parentView">
    <memberships-list />

    
  </section>

  <router-view v-else></router-view>

</template>

<script>
import MembershipsList from "@/components/VideosManager/Data/VideoMemberships/MembershipsList.vue"

export default {
  name: "MembershipsManager",

  components: {
    MembershipsList
  },

  watch: {
    "$route": function(to){
       if (to.path === "/video-management/memberships") {
        this.parentView = true
      } else {
        this.parentView = false
      }
    }
  },

  data: () => ({
    parentView: true
  })
}
</script>

<style scoped lang='scss'>

</style>
