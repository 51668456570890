var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('build-notice',{attrs:{"mode":"full"}}),_c('h3',{staticClass:"subheading"},[_vm._v("Browse Page Data")]),_c('section',{staticClass:"section-container"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('ImageUploader',{attrs:{"title":'Browse Hero Background Image',"widthOptionEnabled":false,"mediaLibraryID":_vm.updatedData.browseHeroBackgroundImageMediaLibraryID},on:{"update":function($event){return _vm.updateImage(
              $event,
              'browseHeroBackgroundImageMediaLibraryID',
              'browseHeroBackgroundImageKey'
            )}}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("This will set the background image in the browse section.")])])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Browse Hero Mode")]),_c('v-select',{attrs:{"solo":"","flat":"","item-text":"name","item-value":"value","items":[
            { name: 'Use light mode for the hero', value: 'light' },
            { name: 'Use dark mode for the hero', value: 'dark' },
          ]},on:{"change":function($event){return _vm.updateStatus()}},model:{value:(_vm.updatedData.browseHeroMode),callback:function ($$v) {_vm.$set(_vm.updatedData, "browseHeroMode", $$v)},expression:"updatedData.browseHeroMode"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("This will set the mode for the browse hero.")])])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Browse Hero Title")]),_c('v-text-field',{attrs:{"id":"field","solo":"","flat":"","required":""},on:{"input":function($event){return _vm.updateStatus()}},model:{value:(_vm.updatedData.browseHeroTitle),callback:function ($$v) {_vm.$set(_vm.updatedData, "browseHeroTitle", $$v)},expression:"updatedData.browseHeroTitle"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("This will set the title for the browse hero.")])])])],1)],1),_c('StickyFooter',{attrs:{"errorMessage":_vm.errorMessage,"status":_vm.status,"buttonText":'Save Settings'},on:{"buttonClicked":function($event){return _vm.saveBrowseSettings()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }