<template>
    <li class="row">
        <div class="item video__list-item">
            <div class="image-container video__list-item-image">
                <img
                    v-if="image"
                    class="image"
                    :src="image"
                    alt=""
                >
                <p v-else>No image</p>
            </div>
            <div class="meta">
                <div class="title-container">
                    <h3 class="title subheading">{{ playlist.title }}</h3>
                    <span
                        class="publish-label"
                        :class="`publish-label--${playlist.publicationData.published ? 'published' : 'draft'}`"
                    >{{ playlist.publicationData.published ? 'published' : 'draft' }}</span>
                </div>
                <p class="video-count">{{ playlist.videos ? playlist.videos.length : 0 }} Video{{ playlist.videos ? playlist.videos.length !== 1
                    ? 's' : '' : 's' }}</p>
            </div>
            <div class="actionable">
                <div class="buttons">
                    <button
                        @click="$emit('editPlaylist', { attributes: playlist, id: playlistID })"
                        class="button button--edit"
                    >
                        Edit
                    </button>
                    <button
                        class="button button--delete"
                        @click="deletePlaylist(playlistID)"
                    >
                        Delete
                    </button>
                </div>
                <!-- <input
        min="1"
        :max="maxPosition"
        id="position-input"
        class="position-input"
        type="number"
        solo
        flat
        v-model="position"
        @change="updateOrder"/> -->
            </div>
        </div>
    </li>
</template>

<script>

export default {
    name: "VideosPlaylistItem",

    props: {
        playlist: {
            type: Object,
            required: true,
        },
        playlistID: {
            type: String,
            required: true,
        },
        maxPosition: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        position: null,
    }),

    created() {
        this.position = this.playlist.order
    },

    watch: {
        playlist: {
            handler() {
                this.position = this.playlist.order
            },
            deep: true
        }
    },

    computed: {
        image() {
            if (this.playlist.imageData) {
                if (this.playlist.imageData.mediaLibraryID && this.playlist.imageData.imageKey) {
                    return `${process.env.VUE_APP_IMAGEURL}/160x90/${this.playlist.imageData.imageKey}`
                }
            } else return ""
            return ""
        }
    },

    methods: {
        deletePlaylist(id) {
            if (confirm("Are you sure you want to delete this playlist?")) {
                this.$emit("deletePlaylist", id)
            }
        },

        // updateOrder(event) {
        //   this.$emit('updateOrder', event.target.value)
        // }
    }
}
</script>

<style scoped lang='scss'>
.row {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-bottom: 16px;
    transition: 0.5s;
}

.item {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    padding-top: 8px;
    margin-bottom: 0;
}

.image-container {
    margin-top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 90px;
    border-right: solid 1px rgba($color: #ccc, $alpha: 0.8);

    p {
        margin: 0;
    }
}

.image {
    height: 100%;
}

.meta {}

.title-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.title {
    margin: 0;
    font-weight: 500;
}

.publish-label {
    padding: 3px 12px;
    margin: 0 0 0 8px;
    font-size: 10px;
    color: #fff;
    background-color: #999;
    border-radius: 4px;

    &--draft {
        background-color: color(draftHighlight);
    }

    &--published {
        background-color: #67247c;
    }
}

.video-count {
    font-size: 11px;
}

.actionable {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons {}

.position-input {
    margin-top: 8px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #DFE3E9;
    padding: 3px;
    margin-left: auto;
}

.button {
    padding: 6px 8px;
    color: #7b6b80;
    font-size: 14px;
    font-weight: 500;
    background-color: #f1f4f8;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-out;

    &--edit {
        margin-right: 10px;

        &:hover {
            color: #fff;
            background-color: #67247c;
        }
    }

    &--delete {
        &:hover {
            color: #fff;
            background-color: $errorRed;
        }
    }
}

@media screen and (max-width: 600px) {
    .image-container {
        display: none;
    }
}
</style>
