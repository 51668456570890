<template>
    <div>
        <build-notice mode="full" />

        <h3 class="subheading">Live Upgrade</h3>
        <section class="section-container">
            <template v-if="showFullSettings">
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Entitlement Display Override</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.entitlementDisplayOverride"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Must match an entitlement in the memberships admin - forces
                                this membership data to display if status is requireUpgrade</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Branding Background</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.brandingBackground"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Will change styling on certain elements</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Branding Text Color</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.brandingTextColor"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Will change styling on certain elements</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Live Upgrade Image</label>
                        <ImageUploader
                            :title="'Live Upgrade Image'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="updatedData.liveUpgradeData.imageMediaLibraryID"
                            @update="updateLiveUpgradeImage($event)"
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Optional</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Locked Video Title</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.lockedVideoTitle"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Locked Video Message</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.lockedVideoMessage"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Button Text</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.buttonText"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="field"
                        >Button Link</label>
                        <v-text-field
                            id="field"
                            v-model="updatedData.liveUpgradeData.buttonLink"
                            solo
                            flat
                            required
                            @input="updateStatus()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Needs to map to an internal page e.g. /video/subscriptions</i>
                        </p>
                    </v-flex>
                </v-layout>
            </template>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Require login entitlement</label>
                    <v-text-field
                        id="field"
                        v-model="updatedData.liveUpgradeData.requireLoginEntitlement"
                        solo
                        flat
                        required
                        @input="updateStatus()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>This must match an entitlement in the memberships admin.</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Require upgrade entitlement</label>
                    <v-text-field
                        id="field"
                        v-model="updatedData.liveUpgradeData.requireUpgradeEntitlement"
                        solo
                        flat
                        required
                        @input="updateStatus()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>This must match an entitlement in the memberships admin.</i></p>
                </v-flex>
            </v-layout>
        </section>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Settings'"
            @buttonClicked="saveUpgradeSettings()"
        />
    </div>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components//Library/BuildNotice/BuildNotice.vue";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
    name: "VideosUpgradeSettings",

    components: {
        BuildNotice,
        ImageUploader,
        StickyFooter,
    },

    data: () => ({
        videosApi: process.env.VUE_APP_VIDEOS_API,
        streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
        modules: null,
        showFullSettings: false,
        configId: null,
        status: null,
        errorMessage: null,
        updatedData: {
            liveUpgradeData: {
                entitlementDisplayOverride: null,
                requireLoginEntitlement: null,
                requireUpgradeEntitlement: null,
                brandingBackground: null,
                brandingTextColor: null,
                imageKey: null,
                imageMediaLibraryID: null,
                lockedVideoTitle: null,
                lockedVideoMessage: null,
                buttonText: null,
                buttonLink: null,
            },
        },
    }),

    created() {
        this.modules = require("@/config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");

        if (process.env.VUE_APP_CLUB_NAME != "LFC") {
            this.showFullSettings = true;
        }
    },

    mounted() {
        this.getUpgradeSettings();
    },

    methods: {
        getUpgradeSettings() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.modules.default.Streamline
                        ? this.streamlineApi
                        : this.videosApi
                        }/config`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log(
                            "retrieved global settings:",
                            response.data.data.attributes
                        );
                        this.updatedData = response.data.data.attributes;
                        this.configId = response.data.data.id;
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem getting global settings", e);
                        // this.status = 'needsSaving'
                        this.errorMessage = "Problem getting global settings";
                    });
            });
        },

        saveUpgradeSettings() {
            const data = {
                data: {
                    type: this.modules.default.Streamline
                        ? "streamline-config"
                        : "video-global-config",
                    attributes: {
                        liveUpgradeData: {
                            entitlementDisplayOverride: this.updatedData.liveUpgradeData
                                .entitlementDisplayOverride
                                ? this.updatedData.liveUpgradeData.entitlementDisplayOverride
                                : "",
                            requireLoginEntitlement: this.updatedData.liveUpgradeData
                                .requireLoginEntitlement
                                ? this.updatedData.liveUpgradeData.requireLoginEntitlement
                                : "",
                            requireUpgradeEntitlement: this.updatedData.liveUpgradeData
                                .requireUpgradeEntitlement
                                ? this.updatedData.liveUpgradeData.requireUpgradeEntitlement
                                : "",
                            brandingBackground: this.updatedData.liveUpgradeData
                                .brandingBackground
                                ? this.updatedData.liveUpgradeData.brandingBackground
                                : "",
                            brandingTextColor: this.updatedData.liveUpgradeData
                                .brandingTextColor
                                ? this.updatedData.liveUpgradeData.brandingTextColor
                                : "",
                            imageKey: this.updatedData.liveUpgradeData.imageKey
                                ? this.updatedData.liveUpgradeData.imageKey
                                : "",
                            imageMediaLibraryID: this.updatedData.liveUpgradeData
                                .imageMediaLibraryID
                                ? this.updatedData.liveUpgradeData.imageMediaLibraryID
                                : "",
                            lockedVideoTitle: this.updatedData.liveUpgradeData
                                .lockedVideoTitle
                                ? this.updatedData.liveUpgradeData.lockedVideoTitle
                                : "",
                            lockedVideoMessage: this.updatedData.liveUpgradeData
                                .lockedVideoMessage
                                ? this.updatedData.liveUpgradeData.lockedVideoMessage
                                : "",
                            buttonText: this.updatedData.liveUpgradeData.buttonText
                                ? this.updatedData.liveUpgradeData.buttonText
                                : "",
                            buttonLink: this.updatedData.liveUpgradeData.buttonLink
                                ? this.updatedData.liveUpgradeData.buttonLink
                                : "",
                        },
                    },
                },
            };

            if (this.modules.default.Streamline) {
                data.data.id = this.configId;
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: this.modules.default.Streamline
                        ? `${this.streamlineApi}/config`
                        : `${this.videosApi}/config/${this.configId}`,
                    headers: { Authorization: jwt },
                    data,
                })
                    .then((response) => {
                        console.log("saved settings:", response);
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving global settings", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving global settings";
                    });
            });
        },

        updateLiveUpgradeImage(payload) {
            // this.status = 'needsSaving'
            this.updatedData.liveUpgradeData.imageKey = payload.imageKey;
            this.updatedData.liveUpgradeData.imageMediaLibraryID =
                payload.mediaLibraryID;
        },

        updateStatus() {
            this.status = "needsSaving";
        },
    },
};
</script>

<style scoped lang="scss">
.half {
    width: 50%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}
</style>
