<template>
    <div>
        <build-notice mode="full" />
        <h3 class="subheading">Category Page Data</h3>
        <section class="section-container">
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="pageTitle"
                    >Page Title</label>
                    <v-text-field
                        id="pageTitle"
                        v-model="globalCategoryData.pageTitle"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will define the title that shows on the hero of the page.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="metaDescription"
                    >Meta Description</label>
                    <v-text-field
                        id="metaDescription"
                        v-model="globalCategoryData.metaDescription"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This is an optional description that goes below the title on the
                            hero.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="metaDescription"
                    >Schema</label>
                    <v-textarea
                        v-model="globalCategoryData.schema"
                        solo
                        flat
                        rows="2"
                    ></v-textarea>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>This is where your custom Schema can be added.</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <ImageUploader
                        :title="'Category Hero Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="globalCategoryData.heroImageMediaLibraryID"
                        @update="updateHeroImage($event)"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>This is the hero image of the categories page.</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="mode"
                    >Hero mode</label>
                    <v-select
                        v-model="globalCategoryData.heroMode"
                        solo
                        flat
                        id="mode"
                        item-text="name"
                        item-value="value"
                        :items="[
                            { name: 'Light', value: 'light' },
                            { name: 'Dark', value: 'dark' },
                        ]"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Choose a mode that matches whether the image is lighter or darker
                            in colour.</i>
                    </p>
                </v-flex>
            </v-layout>
        </section>

        <h3
            class="subheading"
            id="categories"
        >Categories List</h3>
        <section class="section-container">
            <div class="video__filter-button-wrapper">
                <v-btn
                    type="button"
                    class="accent"
                    @click="goToAdd()"
                >
                    <v-icon small>add</v-icon>
                    Add Category
                </v-btn>
            </div>

            <ul>
                <template v-for="(category, index) in categories">
                    <category-card
                        @promptEdit="promptEdit($event)"
                        @promptDelete="deleteCategory($event, category.id)"
                        :key="category.id"
                        :category="category.attributes"
                        :index="index"
                        :id="category.id"
                    />
                </template>
            </ul>
        </section>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Settings'"
            @buttonClicked="saveCategoriesSettings()"
        />
    </div>
</template>

<script>
import axios from "axios";
import CategoryCard from "@/components/VideosManager/Data/VideoCategories/CategoryCard.vue";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";
import BuildNotice from "@/components//Library/BuildNotice/BuildNotice.vue";

export default {
    name: "VideosCategories",

    components: {
        CategoryCard,
        ImageUploader,
        StickyFooter,
        BuildNotice,
    },

    data: () => ({
        videosApi: process.env.VUE_APP_VIDEOS_API,
        streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
        modules: null,
        configId: null,
        status: null,
        errorMessage: null,
        editingPosition: null,
        globalCategoryData: {
            pageTitle: "",
            metaDescription: "",
            schema: "",
            heroMode: "",
            heroImageMediaLibraryID: "",
            heroImageKey: "",
        },
        categories: null,
    }),

    created() {
        this.modules = require("@/config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
            axios({
                method: "GET",
                url: `${this.modules.default.Streamline ? this.streamlineApi : this.videosApi
                    }/config`,
                headers: { Authorization: jwt },
            })
                .then((response) => {
                    console.log("retrieved global category data:", response.data.data);
                    if (response.data.data.attributes.categoryData) {
                        this.globalCategoryData =
                            response.data.data.attributes.categoryData;
                    }
                    this.configId = response.data.data.id;
                })
                .catch((e) => {
                    console.error("Problem getting global category data", e);
                    this.status = "needsSaving";
                });
        });

        this.getCategories();
    },

    methods: {
        updateHeroImage(payload) {
            this.globalCategoryData.heroImageMediaLibraryID = payload.mediaLibraryID;
            this.globalCategoryData.heroImageKey = payload.imageKey;
        },

        goToAdd() {
            this.$router.push({ path: "/video-management/config/categories/create" });
        },

        getCategories() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.videosApi}/categories`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("retrieved CATEGORIES settings:", response.data.data);
                        this.categories = response.data.data;
                    })
                    .catch((e) => {
                        console.error("Problem getting CATEGORIES settings", e);
                        this.status = "needsSaving";
                    });
            });
        },

        saveCategoriesSettings() {
            const data = {
                data: {
                    type: this.modules.default.Streamline
                        ? "streamline-config"
                        : "video-global-config",
                    attributes: {
                        categoryData: this.globalCategoryData,
                    },
                },
            };

            if (this.modules.default.Streamline) {
                data.data.id = this.configId;
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: this.modules.default.Streamline
                        ? `${this.streamlineApi}/config`
                        : `${this.videosApi}/config/${this.configId}`,
                    headers: { Authorization: jwt },
                    data,
                })
                    .then((response) => {
                        console.log("saved settings:", response);
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving CATEGORIES settings", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving CATEGORIES settings";
                    });
            });
        },

        updateImage(payload) {
            this.categoryImage = payload.imageKey;
            this.mediaLibraryID = payload.mediaLibraryID;
        },

        deleteCategory(payload, key) {
            if (
                confirm(
                    `Are you sure you want to delete the category "${this.categories[payload].attributes.name}"? This cannot be undone.`
                )
            ) {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${this.videosApi}/categories/${key}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("Category deleted:", response);
                            this.getCategories();
                        })
                        .catch((e) => {
                            console.error("Problem deleting category", e);
                        });
                });
            }
        },
    },
};
</script>

<style scoped lang='scss'>.half {
    width: 50%;
}

ul {
    padding: 0;
}
</style>
