<template>
  <section class="section-container ml-5 mr-5">
    <h3 class="subheading mt-0">Search for club</h3>
    <div class="crest-search__actions">
      <v-text-field
        class="crest-search__search"
        solo
        flat
        v-model="searchTerm">
      </v-text-field>
      <v-btn class="button"  @click="search(searchTerm)">Search</v-btn>
    </div>
    <template v-if="results">
      <team-crest-options v-for="result in results" :name="result.attributes.teamName" :id="result.id" :key="result.id"/>
    </template>
  </section>
</template>

<script>
import TeamCrestOptions from "@/components/TeamCrests/TeamCrestOptions"
import axios from "axios"

export default {
  name: "TeamCrestSearch",

  components: {
    TeamCrestOptions
  },

  data: () => ({
    api: `${process.env.VUE_APP_TEAMS_API}`,
    searchTerm: "",
    results: null
  }),

  methods: {
    search(team) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/teams?filter[teamName]=${team}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("search response", response)
          this.results = response.data.data
        })
        .catch(e => {
          console.error("Problem getting search data", e);
        });
      })
    }
  }
}
</script>

<style scoped lang='scss'>

.crest-search {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  &__search {
    max-width: 400px;
  }
}

.button {
  background-color: #67247c !important;
  color: #fff !important;
  // margin: 0 auto;
}

</style>
