<template>
  <section>
    <build-notice mode="full" />
    <div class="video__filter-button-wrapper">
      <v-btn
        type="button"
        depressed
        @click="addNew()"
        class="accent"
      >
        <v-icon small>add</v-icon>
        Add New Membership Option
      </v-btn>

    </div>
    <memberships-card v-for="memberships in membershipsAdmin" :key="memberships.id" :data="memberships" @deleteMembership="deleteMembership($event)" />
  </section>
</template>

<script>
import axios from "axios"
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice"
import MembershipsCard from "@/components/VideosManager/Data/VideoMemberships/MembershipsCard/MembershipsCard.vue"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "MembershipsList",

  components: {
    BuildNotice,
    MembershipsCard,
    StickyFooter,
  },

  data: () => ({
    api: process.env.VUE_APP_VIDEOS_API,
    status: null,
    errorMessage: null,
    configId: null,
    membershipsAdmin: {}
  }),

  mounted() {
    this.getMembershipSettings();
  },

  methods: {
    getMembershipSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/memberships`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          // console.log('retrieved MEMBERSHIPS settings:', response.data.data)
          this.membershipsAdmin = response.data.data
        })
        .catch(e => {
          console.error("Problem getting MEMBERSHIPS settings", e)
          this.status = "needsSaving"
        });
      })
    },

    addNew() {
      this.$router.push({ path: "/video-management/memberships/create-membership"})
    },

    deleteMembership(id) {
      if (confirm("Are you sure you want to delete this membership?\nThis is permanent.") == true) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/memberships/${id}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            this.getMembershipSettings();
          })
          .catch(e => {
            console.error("Problem DELETING membership");
            // this.status = 'needsSaving'
          });
        })
      }
    }
  }
}
</script>

<style>

</style>