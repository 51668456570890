<template>
    <section
        class="config-selector"
        v-if="parentView"
    >
        <v-tabs>
            <v-tab :ripple="{ class: 'accent--text' }">Content Feeds</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <video-content-feed-management @isParent="parentView = $event" />
            </v-tab-item>

            <template v-if="browsePagePermissions">
                <v-tab :ripple="{ class: 'accent--text' }">Browse Page</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <videos-browse-settings />
                </v-tab-item>
            </template>

            <template v-if="playlistPagePermissions">
                <v-tab :ripple="{ class: 'accent--text' }">Playlists</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <videos-playlists-settings />
                </v-tab-item>
            </template>

            <template v-if="faqPagePermissions">
                <v-tab :ripple="{ class: 'accent--text' }">Faqs</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <video-faqs />
                </v-tab-item>
            </template>
        </v-tabs>
    </section>

    <router-view v-else></router-view>
</template>

<script>
import VideoContentFeedManagement from "@/components/VideosManager/Data/VideoContentFeeds/VideoContentFeedManagement";
import VideoFaqs from "@/components/VideosManager/VideoFaqs/VideoFaqs.vue";
import VideosPlaylistsSettings from "@/components/VideosManager/Data/VideosPlaylists/VideosPlaylistsSettings";
import VideosBrowseSettings from "@/components/VideosManager/Data/VideosBrowseSettings";

export default {
    name: "ConfigManager",

    components: {
        VideoContentFeedManagement,
        VideosBrowseSettings,
        VideoFaqs,
        VideosPlaylistsSettings,
    },

    watch: {
        $route: function (to, from) {
            if (to.path === "/video-management/content") {
                this.parentView = true;
            } else {
                this.parentView = false;
            }
        },
    },

    data: () => ({
        parentView: true,
        modules: null,
        browsePagePermissions: false,
        faqPagePermissions: false,
        playlistPagePermissions: false,
    }),

    created() {
        this.modules = require("../../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");

        if (process.env.VUE_APP_CLUB_NAME != "LFC") {
            this.browsePagePermissions = true;
            this.faqPagePermissions = true;
        } else {
            this.playlistPagePermissions = true;
        }
    },
};
</script>

<style scoped lang="scss">
.config-selector {
    &__list {
        padding: 0;
        margin: 20px 0 0;
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border: 1px solid #67247c;
        border-radius: 6px;
    }

    &__subheading {
        margin: 0;
        padding: 5px 0 0;
    }
}

.options {
    &__panel {
        display: flex;
        flex-direction: column;
    }

    &__description {
        font-style: italic;
    }
}

.accent {
    width: 200px;
}
</style>
