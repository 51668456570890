<template>
  <section>
    <h3 class="subheading">Content Feed Management</h3>

    <p class="notice">
      This section is where you define the structure of each of your feeds.
      Should you require additional feeds, beyond what is defined here, this
      will need raising as a support ticket
    </p>

    <video-content-feed-item
      v-for="(feed, index) in availableFeeds"
      :key="index"
      :feedID="feed.id"
      :title="getTitle(feed.id)"
      :isApp="checkAppFeed(feed.id)"
    />

    <v-btn :disabled="!superUser" class="accent" @click="addNewFeed()"
      >Add New</v-btn
    >
  </section>
</template>

<script>
import axios from "axios";
import VideoContentFeedItem from "@/components/VideosManager/Data/VideoContentFeeds/VideoContentFeedItem";

export default {
  name: "VideoContentFeedManagement",

  components: {
    VideoContentFeedItem,
  },

  mounted() {
    this.checkRoute();
    if (
      this.$store.state.user.email === "chrisreynolds@urbanzoo.io" ||
      this.$store.state.user.email === "adamhughes@urbanzoo.io" ||
      this.$store.state.user.email === "sergiearruda@urbanzoo.io"
    ) {
      this.superUser = true;
    }
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    superUser: false,
    availableFeeds: null,
    globalSettings: null,
  }),

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  methods: {
    checkRoute() {
      if (this.$route.path === "/video-management/content") {
        this.$emit("isParent", true);
        this.getGlobalSettings();
        this.getFeeds();
      } else {
        this.$emit("isParent", false);
      }
    },

    getFeeds() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.videosApi}/content`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.availableFeeds = response.data.data;
            this.status = "saved";
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem getting content feeds", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem getting feeds";
          });
      });
    },

    getGlobalSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.globalSettings = response.data.data.attributes;
          })
          .catch((e) => {
            console.error("Problem getting global settings", e);
            this.errorMessage = "Problem getting global settings";
          });
      });
    },

    addNewFeed() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.videosApi}/content`,
          headers: { Authorization: jwt },
          data: {
            data: {
              // id: `${this.$uuid.v1()}`,
              type: "video-content",
              attributes: {
                hero: {},
                content: [],
              },
            },
          },
        })
          .then((response) => {
            this.getFeeds();
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem adding new feed", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem adding new feed";
          });
      });
    },

    getTitle(key) {
      for (var feed in this.availableFeeds) {
        if (this.globalSettings && this.globalSettings.feed1ID === key) {
          return this.globalSettings && this.globalSettings.feed1PageName;
        } else if (this.globalSettings && this.globalSettings.feed2ID === key) {
          return this.globalSettings && this.globalSettings.feed2PageName;
        } else if (this.globalSettings && this.globalSettings.feed3ID === key) {
          return this.globalSettings && this.globalSettings.feed3PageName;
        } else if (this.globalSettings && this.globalSettings.feed4ID === key) {
          return this.globalSettings && this.globalSettings.feed4PageName;
        } else if (
          this.globalSettings &&
          this.globalSettings.appFeedID === key
        ) {
          return `${
            this.globalSettings && this.globalSettings.appFeedName
          } : App Only`;
        } else {
          return "Feed not published";
        }
      }
    },

    checkAppFeed(key) {
      if (this.globalSettings) {
        if (key === this.globalSettings.appFeedID) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
