<template>
  <div class="video__list-item">
    <div class="video__list-item-info">
      <label class="label">Name</label>
      <v-text-field
        class="video__list-item-input"
        v-model="data.name"
        solo
        flat
        placeholder="name"
        height="20"
      ></v-text-field>
      <label class="label">Label</label>
      <v-text-field
        class="video__list-item-input"
        v-model="data.label"
        solo
        flat
        placeholder="name"
        height="20"
      ></v-text-field>
      <label class="label">Video Duration Min</label>
      <v-text-field
        class="video__list-item-input"
        type="number"
        v-model="minVal"
        @input="formatData()"
        solo
        flat
        placeholder=""
        height="20"
      ></v-text-field>
      <label class="label">Video Duration Max</label>
      <v-text-field
        class="video__list-item-input"
        type="number"
        @input="formatData()"
        v-model="maxVal"
        solo
        flat
        placeholder=""
        height="20"
      ></v-text-field>
      <!-- <small class="video__list-item-type">{{data.id}}</small> -->
    </div>

    <div class="video__list-item-actions">
      <button class="video__list-item-actions--delete" @click="deletefilter()">
        Delete Filter
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiltersCardDuration",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    if (this.data.videoDurationMin) {
      this.minVal = parseInt(this.data.videoDurationMin);
    }
    if (this.data.videoDurationMax) {
      this.maxVal = parseInt(this.data.videoDurationMax);
    }
  },

  data: () => ({
    minVal: null,
    maxVal: null,
  }),

  methods: {
    deletefilter() {
      this.$emit("deletefilter", this.data);
    },
    formatData() {
      this.data.videoDurationMin = parseInt(this.minVal);
      this.data.videoDurationMax = parseInt(this.maxVal);
    },
  },
};
</script>

<style scoped lang='scss'>
/deep/ .v-input__control {
  min-height: 36px !important;
}
</style>
