<template>
  <section class="config-selector" v-if="parentView">

    <v-tabs v-model="activeTab">
      <v-tab 
        :ripple="{ class: 'accent--text' }">Global Settings</v-tab>
      <v-tab-item
        px-0
        class="main-body">
        <videos-global-settings />
      </v-tab-item>

      <template>
        <v-tab 
          :ripple="{ class: 'accent--text' }">Upgrade Settings</v-tab>
        <v-tab-item
          px-0
          class="main-body">
          <videos-upgrade-settings />
        </v-tab-item>
      </template>

      <v-tab 
        :ripple="{ class: 'accent--text' }">Categories</v-tab>
      <v-tab-item
        px-0
        class="main-body">
        <video-categories />
      </v-tab-item>

      <v-tab 
        :ripple="{ class: 'accent--text' }">Filters</v-tab>
      <v-tab-item
        px-0
        class="main-body">
        <div class="options__panel">
          <filters-list />
        </div>
      </v-tab-item>

      <template v-if="moduleConfig.default.StreamAMGIntegration">
        <v-tab
          :ripple="{ class: 'accent--text' }">Stream Integration</v-tab>
        <v-tab-item
          px-0
          class="main-body">
          <div class="options__panel">
            <stream-integration  />
          </div>
        </v-tab-item>
      </template>

      <template v-if="moduleConfig.default.Streamline">
        <v-tab :ripple="{ class: 'accent--text' }">Streamline</v-tab>
        <v-tab-item
          px-0
          class="main-body">
          <div class="options__panel">
            <streamline-integration  />
          </div>
        </v-tab-item>
      </template>
    </v-tabs>
  </section>
    <router-view v-else></router-view>
</template>

<script>
import FiltersList from "@/components/VideosManager/Data/VideoFilters/FiltersList.vue"
import StickyFooter from "@/components/Library/StickyFooter"
import StreamIntegration from "@/components/VideosManager/Data/VideosStreamSettings"
import StreamlineIntegration from "@/components/VideosManager/Data/VideosStreamlineSettings"
import VideosGlobalSettings from "@/components/VideosManager/Data/VideosGlobalSettings.vue"
import VideosUpgradeSettings from "@/components/VideosManager/Data/VideosUpgradeSettings.vue"
import VideoCategories from "@/components/VideosManager/Data/VideoCategories/VideoCategories.vue"

export default {
  name: "ConfigManager",

  components: {
    FiltersList,
    StickyFooter,
    StreamIntegration,
    StreamlineIntegration,
    VideosGlobalSettings,
    VideosUpgradeSettings,
    VideoCategories
  },

  data: () => ({
    api: process.env.VUE_APP_VIDEOS_API,
    activeTab: null,
    parentView: true,
    moduleConfig: null,
    status: null,
    errorMessage: null,
    updatedData: {
    },
  }),

  watch: {
    "$route": function(to, from){
      console.log("watcher", this.$route)
       if (to.path === "/video-management/config") {
        this.parentView = true
      } else {
        this.parentView = false
      }
    }
  },

  created() {
    this.moduleConfig = require("../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    if (this.$route.query.tab) {
      this.activeTab = Number(this.$route.query.tab)
    }
  }
}
</script>

<style scoped lang="scss">
  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border: 1px solid #67247c;
      border-radius: 6px;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }
  }

  .options {
  &__panel {
  display: flex;
  flex-direction: column;
  }

  &__description {
  font-style: italic;
  }
}

.accent {
  width: 200px;
}
</style>
