<template>
  <div class="video__list-item">
    <div class="video__list-item-info">
      <v-text-field
        class="video__list-item-input"
        v-model="data.name"
        solo
        flat
        placeholder="Name"
        height="20"
        >{{ data.name }}</v-text-field
      >
      <v-text-field
        class="video__list-item-input"
        v-if="
          data.teamID !== undefined &&
          (modules.default.LFCExclusive ||
            this.modules.default.StreamlineFiltersv2)
        "
        v-model="data.teamID"
        solo
        flat
        placeholder="Team ID"
        height="20"
        >{{ data.teamID }}</v-text-field
      >
      <v-text-field
        class="video__list-item-input"
        v-if="
          allowDisplayName &&
          (modules.default.LFCExclusive ||
            this.modules.default.StreamlineFiltersv2)
        "
        v-model="data.displayName"
        solo
        flat
        placeholder="Displayed Name"
        height="20"
        >{{ data.displayName }}</v-text-field
      >
      <!-- <small class="video__list-item-type">{{data.id}}</small> -->
    </div>

    <div class="video__list-item-actions">
      <button class="video__list-item-actions--delete" @click="deletefilter()">
        Delete Filter
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "filtersCard",

  props: {
    data: {
      type: Object,
      required: true,
    },
    allowDisplayName: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    modules: null,
  }),

  created() {
    this.modules = require("../../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  methods: {
    deletefilter() {
      // console.log('FILTER ID', this.data.id)
      this.$emit("deletefilter", this.data);
    },
  },
};
</script>

<style scoped lang='scss'>
/deep/ .v-input__control {
  min-height: 36px !important;
}
</style>
