var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video__list-item"},[_c('div',{staticClass:"video__list-item-info"},[_c('v-text-field',{staticClass:"video__list-item-input",attrs:{"solo":"","flat":"","placeholder":"Name","height":"20"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}},[_vm._v(_vm._s(_vm.data.name))]),(
        _vm.data.teamID !== undefined &&
        (_vm.modules.default.LFCExclusive ||
          this.modules.default.StreamlineFiltersv2)
      )?_c('v-text-field',{staticClass:"video__list-item-input",attrs:{"solo":"","flat":"","placeholder":"Team ID","height":"20"},model:{value:(_vm.data.teamID),callback:function ($$v) {_vm.$set(_vm.data, "teamID", $$v)},expression:"data.teamID"}},[_vm._v(_vm._s(_vm.data.teamID))]):_vm._e(),(
        _vm.allowDisplayName &&
        (_vm.modules.default.LFCExclusive ||
          this.modules.default.StreamlineFiltersv2)
      )?_c('v-text-field',{staticClass:"video__list-item-input",attrs:{"solo":"","flat":"","placeholder":"Displayed Name","height":"20"},model:{value:(_vm.data.displayName),callback:function ($$v) {_vm.$set(_vm.data, "displayName", $$v)},expression:"data.displayName"}},[_vm._v(_vm._s(_vm.data.displayName))]):_vm._e()],1),_c('div',{staticClass:"video__list-item-actions"},[_c('button',{staticClass:"video__list-item-actions--delete",on:{"click":function($event){return _vm.deletefilter()}}},[_vm._v(" Delete Filter ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }