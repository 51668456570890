<template>
  <li class="video__list-item">
    <img class="video__list-item-image" v-if="category.images.portrait.imageKey" :src="imageSrc" alt="Category image" />
    <div class="video__list-item-info">
      <h3 class="video__list-item-heading subheading">{{category.name}}</h3>
      <small class="video__list-item-type">{{ category.titleVisibility == 'hide' ? 'Title visibility: hidden' : 'Title visibility: show' }}</small>
    </div>
    
    <div class="video__list-item-actions">
      <button class="video__list-item-actions--edit" @click="goToEdit(id)">
        Edit
      </button>
      <button class="video__list-item-actions--delete" @click="$emit('promptDelete', index)">
        Delete
      </button>
    </div>

  </li>
</template>

<script>
export default {
  name: "CategoryCard",

  props: {
    category: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    imageSrc: null,
  }),

  created() {
    if (this.category.images) {
      if (this.category.images.portrait) {
        this.imageSrc = `${process.env.VUE_APP_IMAGEURL}/66x99/${this.category.images.portrait.imageKey}`
      }
    }
  },

  methods: {
    goToEdit(id) {
      this.$router.push({ path: `/video-management/config/categories/${id}`})
    }
  }

}
</script>

<style scoped lang='scss'>

</style>
