var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"modal",attrs:{"persistent":"","width":"1000","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[(!_vm.loading)?[_c('v-card-title',{staticClass:"title"},[_vm._v("Playlist details")]),_c('v-card-text',[_c('label',{staticClass:"label",attrs:{"for":"playlistTitle"}},[_vm._v("Title "),(_vm.data.title.match(/(\[HIDE\]\s*)/g))?_c('span',[_vm._v("- This title will be hidden on the playlist card")]):_vm._e()]),_c('v-text-field',{attrs:{"id":"playlistTitle","placeholder":"","solo":"","flat":""},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('ImageUploader',{attrs:{"title":'Playlist Image',"widthOptionEnabled":false,"mediaLibraryID":_vm.data.imageData.mediaLibraryID,"desc":'<p>Recommended size: 352px width by 198px height or a similar 16:9 ratio</p>'},on:{"update":function($event){return _vm.updateImage($event)}}}),_c('label',{staticClass:"label",attrs:{"for":"playlistPublished"}},[_vm._v("Published Status")]),_c('v-select',{attrs:{"id":"playlistPublished","item-text":"name","item-value":"value","items":[
              {
                name: 'Draft',
                value: 0,
              },
              {
                name: 'Published',
                value: 1,
              },
            ],"solo":"","flat":""},model:{value:(_vm.data.publicationData.published),callback:function ($$v) {_vm.$set(_vm.data.publicationData, "published", $$v)},expression:"data.publicationData.published"}}),_c('div',{staticClass:"videos-selection"},[_c('div',{staticClass:"videos-top"},[_c('label',{staticClass:"videos-label subheading"},[_vm._v("Videos - "+_vm._s(_vm.videoData ? _vm.videoData.length : 0)+" Video"+_vm._s(_vm.videoData.length !== 1 ? "s" : ""))]),_c('v-dialog',{staticClass:"fixture-details",attrs:{"width":"1400","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._b({staticClass:"add-video-btn",attrs:{"color":"accent","type":"button"},on:{"on":on,"click":function($event){_vm.isVideoSelectorOpen = true}}},'v-btn',attrs,false),[_vm._v("Add video")])]}}],null,false,2472927697),model:{value:(_vm.isVideoSelectorOpen),callback:function ($$v) {_vm.isVideoSelectorOpen=$$v},expression:"isVideoSelectorOpen"}},[(_vm.isVideoSelectorOpen)?_c('v-card',{staticClass:"card"},[_c('video-listing',{attrs:{"alternativeMode":'selection',"selectedVideos":_vm.videoData.map((el) => el.id)},on:{"selected":function($event){return _vm.addVideo($event)},"unselected":function($event){return _vm.removeVideo($event, true)}}})],1):_vm._e()],1)],1),_c('ul',{staticClass:"video-list"},[(_vm.videoData.length)?_c('draggable',{attrs:{"options":{
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.videoData),callback:function ($$v) {_vm.videoData=$$v},expression:"videoData"}},[_c('transition-group',{staticClass:"featured__items",attrs:{"name":"list-complete"}},_vm._l((_vm.videoData),function(video){return _c('li',{key:video.id,staticClass:"video"},[(
                        video.attributes.imageData &&
                        video.attributes.imageData.landscape &&
                        video.attributes.imageData.landscape.imageKey
                      )?[(video.attributes.imageData)?[(video.attributes.imageData.landscape.imageKey)?_c('img',{staticClass:"video-listing-item__image",attrs:{"src":`${_vm.imageDomain}/160x90/${video.attributes.imageData.landscape.imageKey}`}}):_vm._e()]:_vm._e()]:(video.attributes.assetData)?[(
                          video.attributes.assetData.playbackIds[0].policy ===
                          'signed'
                        )?_c('img',{staticClass:"video-image",attrs:{"src":`https://image.mux.com/${video.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?token=${video.attributes.assetData.thumbnailToken}&width=400&height=200`}}):_c('img',{staticClass:"video-image",attrs:{"src":`https://image.mux.com/${video.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?width=400&height=200`}})]:_vm._e(),_c('div',{staticClass:"video-data"},[_c('h3',{staticClass:"video-title"},[_vm._v(" "+_vm._s(video.attributes.title)+" ")]),_c('div',{staticClass:"video-buttons"},[_c('div',[_c('v-icon',{staticClass:"draggable-item__handle-icon"},[_vm._v("open_with")]),_c('v-icon',{staticClass:"video-delete",attrs:{"color":"accent","right":""},on:{"click":function($event){return _vm.removeVideo(video.id, false)}}},[_vm._v("remove_circle")])],1)])])],2)}),0)],1):_vm._e()],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outline":"","flat":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{class:{ success: _vm.savePlaylistStatus == 'Saved!' },attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.savePlaylist()}}},[_vm._v(" "+_vm._s(_vm.savePlaylistStatus)+" ")])],1)]:_c('p',{staticClass:"loading"},[_vm._v("Loading...")])],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }