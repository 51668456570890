<template>

  <section class="section-container ml-5 mr-5" >
    
    <h3 class="subheading mt-0">Crest Slots</h3>
    <template v-if="!crestSlots">
      <v-btn @click="initialiseData()">Initialise Data</v-btn>
    </template>

    <template v-else>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.homepageTakeover" title="crestSlots.homepageTakeover">MatchdayTakeover</label>
          <v-select
            id="crestSlots.homepageTakeover"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.homepageTakeover"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the matchday takeover component.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.highlightNextMatch" title="crestSlots.highlightNextMatch">Highlight Bar - Next Match</label>
          <v-select
            id="crestSlots.highlightNextMatch"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.highlightNextMatch"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the homepage Highlight bar for both next match and upcoming match.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.highlightLastMatch" title="crestSlots.highlightLastMatch">Highlight Bar - Last Match</label>
          <v-select
            id="crestSlots.highlightLastMatch"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.highlightLastMatch"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the homepage highlight bar for last match.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.highlightMiniTableClient" title="crestSlots.highlightMiniTableClient">Highlight Bar - Mini Table Client</label>
          <v-select
            id="crestSlots.highlightMiniTableClient"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.highlightMiniTableClient"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the client club crest style to use on the homepage Highlight bar for the mini table.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.highlightMiniTableOther" title="crestSlots.highlightMiniTableOther">Highlight Bar - Mini Table Other</label>
          <v-select
            id="crestSlots.highlightMiniTableOther"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.highlightMiniTableOther"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the other club crest styles to use on the homepage Highlight bar for the mini table.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.featuredLastMatch" title="crestSlots.featuredLastMatch">Featured Last Match</label>
          <v-select
            id="crestSlots.featuredLastMatch"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.featuredLastMatch"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the featured last match block on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.featuredNextMatch" title="crestSlots.featuredNextMatch">Featured Next Match</label>
          <v-select
            id="crestSlots.featuredNextMatch"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.featuredNextMatch"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the featured next match block on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.fullTable" title="crestSlots.fullTable">Full Table</label>
          <v-select
            id="crestSlots.fullTable"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.fullTable"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set your crest style to use on the league table on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.fullTableClient" title="crestSlots.fullTableClient">Full Table - Your Crest - Hover</label>
          <v-select
            id="crestSlots.fullTableClient"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.fullTableClient"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set your team crest style when hovered to use on the league table on the matches page.</i></p>
        </v-flex>
      </v-layout>
          <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.fullTableOther" title="crestSlots.fullTableOther">Full Table - Other Crests</label>
          <v-select
            id="crestSlots.fullTableOther"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.fullTableOther"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the other crest styles to use on the league table on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.fullTableHover" title="crestSlots.fullTableHover">Full Table - Hover</label>
          <v-select
            id="crestSlots.fullTableHover"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.fullTableHover"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style of other clubs when hovered clubs to use on the league table on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCard" title="crestSlots.matchCard">Match Card</label>
          <v-select
            id="crestSlots.matchCard"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCard"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match card on the matches page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreHero" title="crestSlots.matchCentreHero">Match Centre Hero</label>
          <v-select
            id="crestSlots.matchCentreHero"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreHero"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match centre hero section.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreMinByMin" title="crestSlots.matchCentreMinByMin">Match Centre Min By Min Header</label>
          <v-select
            id="crestSlots.matchCentreMinByMin"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreMinByMin"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match centre Min By Min header.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreSidebarLineups" title="crestSlots.matchCentreSidebarLineups">Match Centre Sidebar - Lineups</label>
          <v-select
            id="crestSlots.matchCentreSidebarLineups"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreSidebarLineups"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match centre sidebar - lineups.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreSidebarStats" title="crestSlots.matchCentreSidebarStats">Match Centre Sidebar - Stats</label>
          <v-select
            id="crestSlots.matchCentreSidebarStats"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreSidebarStats"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match centre sidebar - stats.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreSidebarScores" title="crestSlots.matchCentreSidebarScores">Match Centre Sidebar - Scores</label>
          <v-select
            id="crestSlots.matchCentreSidebarScores"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreSidebarScores"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the crest style to use on the match centre sidebar - scores.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreSidebarTableClient" title="crestSlots.matchCentreSidebarTableClient">Match Centre Sidebar - Table - Your Crest</label>
          <v-select
            id="crestSlots.matchCentreSidebarTableClient"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreSidebarTableClient"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set your crest style to use on the mini table on the match centre page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchCentreSidebarTableOther" title="crestSlots.matchCentreSidebarTableOther">Match Centre Sidebar - Table - Other Crests</label>
          <v-select
            id="crestSlots.matchCentreSidebarTableOther"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchCentreSidebarTableOther"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the other crest styles to use on the mini table on the match centre page.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.lineupWidgetClient" title="crestSlots.lineupWidgetClient">Lineup widget - Your Crest</label>
          <v-select
            id="crestSlots.lineupWidgetClient"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.lineupWidgetClient"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set your club crest style to use  in the lineup content widget.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.lineupWidgetOther" title="crestSlots.lineupWidgetOther">Lineup widget - Other Crests</label>
          <v-select
            id="crestSlots.lineupWidgetOther"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.lineupWidgetOther"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the preferred crest style for other teams to use in the lineup content widget.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchStatsWidgetClient" title="crestSlots.matchStatsWidgetClient">Match Stats widget - Your Crest</label>
          <v-select
            id="crestSlots.matchStatsWidgetClient"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchStatsWidgetClient"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set your club crest style to use in the match stats content widget.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5>
          <label class="label" for="crestSlots.matchStatsWidgetOther" title="crestSlots.matchStatsWidgetOther">Match Stats widget - Other Crests</label>
          <v-select
            id="crestSlots.matchStatsWidgetOther"
            item-text="name"
            item-value="value"
            :items="options"
            v-model="crestSlots.matchStatsWidgetOther"
            solo
            flat
            @change="updateSlots()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the preferred crest style for other teams to use in the match stats content widget.</i></p>
        </v-flex>
      </v-layout>
    </template>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "TeamCrestSlots",

  mounted() {
    this.getSlots()
  },

  data: () => ({
    api: `${process.env.VUE_APP_GLOBALVARIABLES_API}`,
    pageLoaded: false,
    options: [
      {name: "Default", value: "defaultCrest"},
      {name: "Light Mode", value: "lightModeCrest"},
      {name: "Dark Mode", value: "darkModeCrest"}
    ],
    crestSlots: null,
    type: null,
    varid: null
  }),

  methods: {
    getSlots() {
      console.log("get slots")
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          this.crestSlots = response.data.data.attributes.crestSlots
          this.type = response.data.data.type
          this.varid = response.data.data.id
          this.$store.commit("completeLoading");
        }).catch((error) => {
          console.error("global vars error", error)
          this.$store.commit("completeLoading");
        })
      })
    },

    updateSlots() {
      this.$store.commit("startLoading");
      console.log("slot")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "patch",
          url: `${this.api}/${this.varid}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              "type": this.type,
              "id": this.varid,
              "attributes": {
                "crestSlots": this.crestSlots
              }
            }
          }
        })
        .then(response => {
          this.$store.commit("completeLoading");
        }).catch((error) => {
          console.error("global vars patch error", error)
          this.$store.commit("completeLoading");
        })
      })

    },
    initialiseData() {
      this.$store.commit("startLoading");
      this.crestSlots = {
        homepageTakeover: "defaultCrest",
        matchdayTakeover: "defaultCrest",
        highlightNextMatch: "defaultCrest",
        highlightLastMatch: "defaultCrest",
        highlightMiniTableClient: "defaultCrest",
        highlightMiniTableOther: "defaultCrest",
        featuredNextMatch: "defaultCrest",
        featuredLastMatch: "defaultCrest",
        fullTable: "defaultCrest",
        fullTableOther: "defaultCrest",
        fullTableClient: "defaultCrest",
        fullTableHover: "defaultCrest",
        matchCard: "defaultCrest",
        matchCentreHero: "defaultCrest",
        matchCentreMinByMin: "defaultCrest",
        matchCentreSidebarLineups: "defaultCrest",
        matchCentreSidebarStats: "defaultCrest",
        matchCentreSidebarScores: "defaultCrest",
        matchCentreSidebarTableClient: "defaultCrest",
        matchCentreSidebarTableOther: "defaultCrest",
        lineupWidgetClient: "defaultCrest",
        lineupWidgetOther: "defaultCrest",
        matchStatsWidgetClient: "defaultCrest",
        matchStatsWidgetOther: "defaultCrest"
      }
      this.updateSlots()
    }
  }
}
</script>

<style scoped lang='scss'>

  .half {
    max-width: 50%;
    min-width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }

</style>
