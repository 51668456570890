<template>
  <section class="faq-accordion">
    <v-btn
      class="add-new"
      color="accent"
      outline
      flat
      @click="addRow(), updateData()"
    >
      + Add Section
    </v-btn>
    <div
      v-for="(section, index) in faq"
      :key="section.id"
      class="faq-section section-container"
      :class="visible === `faq-section--${index}`"
    >
      <v-layout row>
        <v-flex mr-5 class="half">
          <v-text-field
            class="faq-accordion__section-name"
            solo
            flat
            v-model="section.title"
            placeholder="Enter Section Title"
            @input="updateData()"
            :disabled="visible === `faq-section--${index}` ? false : true"
          ></v-text-field>
        </v-flex>
        <v-flex class="half delete-section">
          <template v-if="visible === `faq-section--${index}`">
            <v-btn
              class="faq-accordion__delete"
              color="#FF5252"
              outline
              flat
              @click="deleteRow(section.id)"
            >
              Delete Section
            </v-btn>
            <v-btn
              class="faq-accordion__toggle hide-button"
              color="accent"
              outline
              flat
              @click="togglePanel(`faq-section--${index}`)"
            >
              Close
            </v-btn>
          </template>
          <v-btn
            v-else
            class="faq-accordion__toggle"
            color="accent"
            outline
            flat
            @click="togglePanel(`faq-section--${index}`)"
          >
            Edit
          </v-btn>
        </v-flex>
      </v-layout>

      <template v-if="visible === `faq-section--${index}`">
        <template v-for="(question, index) in section.question">
          <div
            :key="question.index"
            v-if="visible"
            class="faq-accordion__question-section"
          >
            <v-layout row>
              <v-flex mr-5 class="half">
                <v-text-field
                  class="faq-accordion__question-title"
                  solo
                  flat
                  :key="question.index"
                  v-model="section.question[index].heading"
                  placeholder="Enter Question Title"
                  @input="updateData()"
                ></v-text-field>
              </v-flex>
              <v-flex class="half delete-section delete-section--question">
                <v-btn
                  class="faq-accordion__edit"
                  color="accent"
                  outline
                  flat
                  @click="editQuestion(index, section.id)"
                >
                  Edit question
                </v-btn>
                <v-btn
                  class="faq-accordion__delete"
                  color="#FF5252"
                  outline
                  flat
                  @click="deleteQuestion(index, section.id), updateData()"
                >
                  Delete question
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row class="faq-accordion__question-answer">
              <tinymce-editor
                class="faq-accordion__question-answer"
                v-if="index === displayQuestion"
                v-model="section.question[index].question"
                api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                :init="{
                  height: '400px',
                  menubar: false,
                  plugins:
                    'table link paste hr wordcount lists textpattern code',
                  toolbar:
                    'undo redo | styleselect | bold italic | \
                link blockquote | \
                hr | \
                bullist numlist | removeformat | code',
                  resize: false,
                  textpattern_patterns: [
                    {
                      start: '//button',
                      replacement: '<a class=\'button \'>Button here</a>',
                    },
                  ],
                  link_class_list: [
                    { title: 'None', value: '' },
                    {
                      title: 'Primary on Dark',
                      value: 'button button--primary-dark',
                    },
                    {
                      title: 'Primary on Light',
                      value: 'button button--primary-light',
                    },
                    {
                      title: 'Ghost on Dark',
                      value: 'button button--ghost-dark',
                    },
                    {
                      title: 'Ghost on Light',
                      value: 'button button--ghost-light',
                    },
                    {
                      title: 'Label on Dark',
                      value: 'button button--label-dark',
                    },
                    {
                      title: 'Label on Light',
                      value: 'button button--label-light',
                    },
                  ],
                  style_formats: [
                    {
                      title: 'Inline',
                      items: [
                        { title: 'Bold', icon: 'bold', format: 'bold' },
                        { title: 'Italic', icon: 'italic', format: 'italic' },
                        {
                          title: 'Underline',
                          icon: 'underline',
                          format: 'underline',
                        },
                      ],
                    },
                  ],
                  style_formats_merge: false,
                  block_formats:
                    'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
                }"
              ></tinymce-editor>
            </v-layout>
          </div>
        </template>

        <v-btn
          class="faq-accordion__add-question"
          color="accent"
          outline
          flat
          @click="addQuestion(index), updateData()"
        >
          Add Question
        </v-btn>
      </template>
    </div>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Settings'"
      @buttonClicked="saveFaqSettings()"
    />
  </section>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "VideoFAQs",

  components: {
    "tinymce-editor": Editor,
    StickyFooter,
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    faq: [],
    visible: "",
    updatedData: null,
    status: null,
    errorMessage: null,
    configId: null,
    displayQuestion: null,
  }),

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    this.getFaqSettings();
  },

  methods: {
    getFaqSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("retrieved faq settings:", response.data);
            this.faq = response.data.data.attributes.faqs
              ? response.data.data.attributes.faqs
              : [];
            this.configId = response.data.data.id;
          })
          .catch((e) => {
            console.error("Problem getting faq settings", e);
            this.status = "needsSaving";
          });
      });
    },

    saveFaqSettings() {
      const data = {
        data: {
          type: this.modules.default.Streamline
            ? "streamline-config"
            : "video-global-config",
          attributes: {
            faqs: this.faq,
          },
        },
      };

      if (this.modules.default.Streamline) {
        data.data.id = this.configId;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: this.modules.default.Streamline
            ? `${this.streamlineApi}/config`
            : `${this.videosApi}/config/${this.configId}`,
          headers: { Authorization: jwt },
          data,
        })
          .then((response) => {
            console.log("saved settings:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving global settings", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving global settings";
          });
      });
    },

    updateStatus() {
      this.status = "saved";
    },

    updateData() {
      this.status = "needsSaving";
    },

    addRow() {
      console.log(this.faq);
      this.faq.push({
        id: this.$uuid.v1(),
        title: "",
        question: [],
      });
    },

    deleteRow(section) {
      if (confirm("Are you sure you want to delete this section?")) {
        var item = this.faq.findIndex((item) => item.id === section);
        this.faq.splice(item, 1);
      }
    },

    addQuestion(index) {
      this.faq[index].question.push({
        heading: "",
        question: "",
      });
    },

    deleteQuestion(index, section) {
      if (confirm("Are you sure you want to delete this question?")) {
        var sectionNumber = this.faq.findIndex((item) => item.id === section);
        this.faq[sectionNumber].question.splice(index, 1);
      }
    },

    editQuestion(index, section) {
      console.log("waht've I got", index, section);
      this.displayQuestion = index;
    },

    togglePanel(panel) {
      this.displayQuestion = null;
      if (panel === this.visible) {
        this.visible = "";
      } else {
        this.visible = panel;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new {
  display: flex;
  margin: 0 0 24px auto;
}

.faq-accordion {
  &__question-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
  }

  &__section-container {
    display: flex;
  }

  &__action-container {
    position: absolute;
    left: 100%;
    top: 50px;
  }

  &__button {
    position: relative;
    left: -45px;
    top: 40px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  &__add-question {
    margin-top: 24px;
  }

  &__section-name {
    margin: 24px 16px 0px;
  }

  &__question-section {
    margin: 8px;
    padding: 16px 16px 0px;
    background-color: #efefef;
    border-radius: 4px;
  }

  &__question-answer {
    margin-bottom: 16px;
  }
}

.subheading {
  &:hover {
    cursor: pointer;
  }

  &__label {
    position: relative;
    top: -2px;
    margin: 0 0 0 8px;
    padding: 4px 8px;
    color: #67247c;
    font-size: 12px;
    font-weight: 400;
    background-color: #e6e8f1;
    border-radius: 4px;
  }
}

//CR Amends
.delete-section {
  display: flex;
  justify-content: flex-end;
  padding: 16px;

  &--question {
    padding: 0px !important;
  }
}

.faq-section {
  border: 1px solid #ccc;
  padding: 0 !important;
}

.hide-button {
  margin-bottom: 12px;
}

.question-label {
  position: relative;
  margin-top: 40px;

  &:before {
    position: absolute;
    content: "";
    border-top: 1px solid $lightergrey;
    top: -16px;
    left: 16px;
    right: -192px;
  }
}
</style>