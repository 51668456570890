<template>
  <div v-if="data" class="video__list-item">
    <div class="video__list-item-info">
      <h2 class="video__list-item-heading subheading">{{data.attributes.title}}</h2>
      <small class="video__list-item-type">{{data.id}}</small>
    </div>

    <div class="video__list-item-actions">
      <button class="video__list-item-actions--edit" @click="editMembership(data.id)">
        Edit
      </button>
      <button class="video__list-item-actions--delete" @click="deleteMembership(data.id)">
       Delete
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: "MembershipsCard",

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    editMembership(id) {
      this.$router.push({ path: `/video-management/memberships/${id}`})
    },
    deleteMembership(id) {
      this.$emit("deleteMembership",id)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
