<template>
  <div>
    <build-notice mode="full" />
    <h3 class="subheading">Brand & Global Settings</h3>

    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Brand Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.brandName"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the brand name for your video platform.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader
            :title="'Logo Light'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.logoLightMediaLibraryID"
            @update="
              updateImage($event, 'logoLightMediaLibraryID', 'logoLightKey')
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Video platform logo to be used on light backgrounds.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader
            :title="'Logo Dark'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.logoDarkMediaLibraryID"
            @update="
              updateImage($event, 'logoDarkMediaLibraryID', 'logoDarkKey')
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Video platform logo to be used on dark backgrounds.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader
            :title="'Fallback Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.fallbackImageMediaLibraryID"
            @update="
              updateImage(
                $event,
                'fallbackImageMediaLibraryID',
                'fallbackImageKey'
              )
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will be used as a placeholder should thumbnail images not be
              found on video objects.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Use logo on subnav</label>
          <v-select
            v-model="updatedData.subnavWithLogo"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Yes, sub nav with logo', value: true },
              { name: 'No, sub nav without logo', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Choose whether to include logo on subnav.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Sub Nav Logo Style</label>
          <v-select
            v-model="updatedData.subnavLogoIsLight"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Sub nav with logo is Light', value: true },
              { name: 'Sub nav with logo is Dark', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Set logo colour to use in subnav.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Hide Adverts</label>
          <v-select
            v-model="updatedData.hideAdvertsFromVideos"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Yes, hide advertising sections', value: true },
              { name: 'No, show advertising sections', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Hide major advertising sections from all videos pages.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Support Email</label>
          <v-text-field
            id="field"
            v-model="updatedData.supportEmail"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>General support email for videos.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Feeds</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 1 ID</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed1ID"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <v-btn
                class="accent"
                :disabled="!updatedData.feed1ID"
                @click="goToFeed(updatedData.feed1ID)"
                >Go To Feed</v-btn
              >
            </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 1 Page Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed1PageName"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match a video folder in the web project. This can only
              be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 1 Page Slug</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed1PageSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match the relevant path in the web project. This can
              only be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 2 ID</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed2ID"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <v-btn
                class="accent"
                :disabled="!updatedData.feed2ID"
                @click="goToFeed(updatedData.feed2ID)"
                >Go To Feed</v-btn
              >
            </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 2 Page Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed2PageName"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match a video folder in the web project. This can only
              be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 2 Page Slug</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed2PageSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match the relevant path in the web project. This can
              only be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Show Feed 2</label>
          <v-select
            v-model="updatedData.feed2Enabled"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Yes, feed is enabled', value: true },
              { name: 'No, feed should not display', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>You can disable this feed. A full website build is required.</i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 3 ID</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed3ID"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <v-btn
                class="accent"
                :disabled="!updatedData.feed3ID"
                @click="goToFeed(updatedData.feed3ID)"
                >Go To Feed</v-btn
              >
            </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 3 Page Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed3PageName"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match a video folder in the web project. This can only
              be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 3 Page Slug</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed3PageSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match the relevant path in the web project. This can
              only be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Show Feed 3</label>
          <v-select
            v-model="updatedData.feed3Enabled"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Yes, feed is enabled', value: true },
              { name: 'No, feed should not display', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>You can disable this feed. A full website build is required.</i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 4 ID</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed4ID"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <v-btn
                class="accent"
                :disabled="!updatedData.feed4ID"
                @click="goToFeed(updatedData.feed4ID)"
                >Go To Feed</v-btn
              >
            </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 4 Page Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed4PageName"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match a video folder in the web project. This can only
              be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Feed 4 Page Slug</label>
          <v-text-field
            id="field"
            v-model="updatedData.feed4PageSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match the relevant path in the web project. This can
              only be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Show Feed 4</label>
          <v-select
            v-model="updatedData.feed4Enabled"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Yes, feed is enabled', value: true },
              { name: 'No, feed should not display', value: false },
            ]"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>You can disable this feed. A full website build is required.</i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Video Player Slug</label>
          <v-text-field
            id="field"
            v-model="updatedData.videoPlayerSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This must match the relevant path in the web project. This can
              only be changed with development support from Urban Zoo.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="upgradeSlug">Upgrades slug</label>
          <v-text-field
            id="upgradeSlug"
            v-model="updatedData.upgradeSlug"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Some buttons will direct you to this url by default when a user
              needs to buy a package to watch/listen to a piece of media.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">App Feed ID</label>
          <v-text-field
            id="field"
            v-model="updatedData.appFeedID"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <v-btn
                v-if="updatedData.appFeedID"
                class="accent"
                :disabled="!updatedData.appFeedID"
                @click="goToFeed(updatedData.appFeedID)"
                >Go To Feed</v-btn
              >
            </i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">App Feed Name</label>
          <v-text-field
            id="field"
            v-model="updatedData.appFeedName"
            :disabled="!superUser"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Settings'"
      @buttonClicked="saveGlobalSettings()"
    />
  </div>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components//Library/BuildNotice/BuildNotice.vue";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "VideosGlobalSettings",

  components: {
    BuildNotice,
    ImageUploader,
    StickyFooter,
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    superUser: true,
    configId: null,
    status: null,
    errorMessage: null,
    updatedData: {
      brandName: null,
      logoLightKey: null,
      logoLightMediaLibraryID: null,
      logoDarkKey: null,
      logoDarkMediaLibraryID: null,
      fallbackImageKey: null,
      fallbackImageMediaLibraryID: null,
      subnavWithLogo: null,
      subnavLogoIsLight: null,
      hideAdvertsFromVideos: null,
      supportEmail: null,
      feed1ID: "feed1",
      feed1PageName: null,
      feed1PageSlug: null,
      feed2ID: "feed2",
      feed2Enabled: null,
      feed2PageName: null,
      feed2PageSlug: null,
      feed3ID: null,
      feed3Enabled: null,
      feed3PageName: null,
      feed3PageSlug: null,
      feed4ID: null,
      feed4Enabled: null,
      feed4PageName: null,
      feed4PageSlug: null,
      videoPlayerSlug: null,
      upgradeSlug: null,
      appFeedID: null,
      appFeedName: null,
    },
  }),

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    this.getGlobalSettings();
    if (
      this.$store.state.user.email === "chrisreynolds@urbanzoo.io" ||
      this.$store.state.user.email === "adamhughes@urbanzoo.io"
    ) {
      this.superUser = true;
    }
  },

  methods: {
    getGlobalSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log(
              "retrieved global settings:",
              response.data.data.attributes
            );
            this.updatedData = response.data.data.attributes;
            this.configId = response.data.data.id;
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem getting global settings", e);
            // this.status = 'needsSaving'
            this.errorMessage = "Problem getting global settings";
          });
      });
    },

    saveGlobalSettings() {
      const data = {
        data: {
          type: this.modules.default.Streamline
            ? "streamline-config"
            : "video-global-config",
          attributes: {
            brandName: this.updatedData.brandName
              ? this.updatedData.brandName
              : "",
            logoDarkKey: this.updatedData.logoDarkKey
              ? this.updatedData.logoDarkKey
              : "",
            logoDarkMediaLibraryID: this.updatedData.logoDarkMediaLibraryID
              ? this.updatedData.logoDarkMediaLibraryID
              : "",
            logoLightKey: this.updatedData.logoLightKey
              ? this.updatedData.logoLightKey
              : "",
            logoLightMediaLibraryID: this.updatedData.logoLightMediaLibraryID
              ? this.updatedData.logoLightMediaLibraryID
              : "",
            fallbackImageKey: this.updatedData.fallbackImageKey
              ? this.updatedData.fallbackImageKey
              : "",
            fallbackImageMediaLibraryID: this.updatedData
              .fallbackImageMediaLibraryID
              ? this.updatedData.fallbackImageMediaLibraryID
              : "",
            subnavWithLogo: this.updatedData.subnavWithLogo
              ? this.updatedData.subnavWithLogo
              : false,
            subnavLogoIsLight: this.updatedData.subnavLogoIsLight
              ? this.updatedData.subnavLogoIsLight
              : false,
            hideAdvertsFromVideos: this.updatedData.hideAdvertsFromVideos
              ? this.updatedData.hideAdvertsFromVideos
              : false,
            supportEmail: this.updatedData.supportEmail
              ? this.updatedData.supportEmail
              : "",
            feed1ID: this.updatedData.feed1ID ? this.updatedData.feed1ID : "",
            feed1PageName: this.updatedData.feed1PageName
              ? this.updatedData.feed1PageName
              : "",
            feed1PageSlug: this.updatedData.feed1PageSlug
              ? this.updatedData.feed1PageSlug
              : "",
            feed2ID: this.updatedData.feed2ID ? this.updatedData.feed2ID : "",
            feed2Enabled: this.updatedData.feed2Enabled
              ? this.updatedData.feed2Enabled
              : false,
            feed2PageName: this.updatedData.feed2PageName
              ? this.updatedData.feed2PageName
              : "",
            feed2PageSlug: this.updatedData.feed2PageSlug
              ? this.updatedData.feed2PageSlug
              : "",
            feed3ID: this.updatedData.feed3ID ? this.updatedData.feed3ID : "",
            feed3Enabled: this.updatedData.feed3Enabled
              ? this.updatedData.feed3Enabled
              : false,
            feed3PageName: this.updatedData.feed3PageName
              ? this.updatedData.feed3PageName
              : "",
            feed3PageSlug: this.updatedData.feed3PageSlug
              ? this.updatedData.feed3PageSlug
              : "",
            feed4ID: this.updatedData.feed4ID ? this.updatedData.feed4ID : "",
            feed4Enabled: this.updatedData.feed4Enabled
              ? this.updatedData.feed4Enabled
              : false,
            feed4PageName: this.updatedData.feed4PageName
              ? this.updatedData.feed4PageName
              : "",
            feed4PageSlug: this.updatedData.feed4PageSlug
              ? this.updatedData.feed4PageSlug
              : "",
            videoPlayerSlug: this.updatedData.videoPlayerSlug
              ? this.updatedData.videoPlayerSlug
              : "",
            upgradeSlug: this.updatedData.upgradeSlug
              ? this.updatedData.upgradeSlug
              : "",
            appFeedID: this.updatedData.appFeedID
              ? this.updatedData.appFeedID
              : "",
            appFeedName: this.updatedData.appFeedName
              ? this.updatedData.appFeedName
              : "",
          },
        },
      };

      if (this.modules.default.Streamline) {
        data.data.id = this.configId;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: this.modules.default.Streamline
            ? `${this.streamlineApi}/config`
            : `${this.videosApi}/config/${this.configId}`,
          headers: { Authorization: jwt },
          data,
        })
          .then((response) => {
            console.log("saved settings:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving global settings", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving global settings";
          });
      });
    },

    updateImage(payload, id, key) {
      // this.status = 'needsSaving'
      this.updatedData[id] = payload.mediaLibraryID;
      this.updatedData[key] = payload.imageKey;

      if (payload.mediaLibraryID) {
        this.updatedData[id] = payload.mediaLibraryID;
        this.updatedData[key] = payload.imageKey;
      } else {
        this.updatedData[id] = null;
        this.updatedData[key] = null;
      }
    },

    updateStatus() {
      this.status = "needsSaving";
    },

    goToFeed(id) {
      console.log("going to feed", id);
      this.$router.push(`content/content-feeds/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}
</style>
