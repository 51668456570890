<template>

  <section class="section-container">
    <p class="notice">Streamline: In Active Development</p>
  </section>

</template>

<script>
export default {
  name: "StreamlineManager"
}
</script>

<style scoped lang='scss'>

</style>
