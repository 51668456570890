<template>
  <section>
    <h3 class="subheading">Playlists data</h3>
    <ul v-if="!loading" class="playlists">
      <draggable
        v-if="playlists"
        v-model="playlists"
        :options="{
          ghostClass: 'ghost',
          dragClass: 'sortable-drag'
        }"
        @start="drag=true"
        @end="drag=false, status = 'needsSaving'">

        <transition-group name="list-complete" class="featured__items">

          <videoPlaylistItem 
          v-for="playlist in playlists" 
          class="row draggable-item" 
          :maxPosition="playlists.length"
          :key="playlist.id" 
          :playlistID="playlist.id"
          :playlist="playlist.attributes"
          @editPlaylist="editPlaylist($event)"
          @deletePlaylist="deletePlaylist($event)"
          @updateOrder="reorderPlaylistsInput($event, playlist.attributes.order)"
          />
        </transition-group>
      </draggable>
    </ul>
    <p v-else>Loading...</p>

    <v-dialog
    class="modal"
    v-model="showAddPlaylistModal"
    persistent
    width="600"
    lazy
    >
      <slot slot="activator"></slot>
        <v-card>
          <v-card-title class="modal-title subheading">Playlist details</v-card-title>

          <v-card-text>
              <label class="label" for="playlistTitle">Title</label>
              <v-text-field id="playlistTitle" placeholder="" solo flat v-model="newPlaylistTitle"></v-text-field>
              <p v-if="creationError" class="error-text">{{ creationError }}</p>
          </v-card-text>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              outline
              flat
              @click="showAddPlaylistModal = false"
              >
              Cancel
              </v-btn>

              <v-btn
              color="accent"
              outline
              flat
              @click="addPlaylist()"
              >
              Create Playlist
              </v-btn>
          </v-card-actions>
      </v-card>

    </v-dialog>
    <VideosPlaylistModal 
    v-if="isPlaylistModalOpen"
    @savePlaylist="savePlaylist($event)"
    @closeModal="isPlaylistModalOpen = false" 
    :playlistID="selectedPlaylist" />
    
    <v-btn type="button" @click="openModal()" class="accent">Add new playlist</v-btn>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save Playlist Order'" @buttonClicked="savePlaylistsOrder()" />

  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import StickyFooter from "@/components/Library/StickyFooter"
import videoPlaylistItem from "./VideosPlaylistItem.vue"
import VideosPlaylistModal from "./VideosPlaylistModal.vue"

export default {
  name: "VideosPlaylistsSettings",

  components: {
    draggable,
    StickyFooter,
    videoPlaylistItem,
    VideosPlaylistModal
  },

  data: () => ({
    api: `${process.env.VUE_APP_STREAMLINEAPI}/playlists`,
    playlists: [],
    name: null,
    status: "needsSaving",
    errorMessage: null,
    creationError: "",
    showAddPlaylistModal: false,
    newPlaylistTitle: "",
    loading: true,
    selectedPlaylist: null,
    isPlaylistModalOpen: false
  }),

  mounted() {
    this.fetchPlaylists()
  },

  methods: {
    openModal() {
      this.newPlaylistTitle = ""
      this.showAddPlaylistModal = true;
    },

    fetchPlaylists() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}`,
          headers: {"Content-Type": "application/json", Authorization: jwt}
        })
        .then(response => {
          console.log("Playlist response:", response);
          this.playlists = response.data.data;
          this.loading = false;
        })
        .catch(e => {
          console.error("Problem fetching playlist", e);
        });
      })
    },

    addPlaylist() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              attributes: {
                title: this.newPlaylistTitle
              },
            },
          }
        })
        .then(response => {
          this.loading = true
          this.errorMessage = ""
          this.creationError = ""
          console.log("Playlist created:", response);
          this.fetchPlaylists()
          this.showAddPlaylistModal = false
        })
        .catch(e => {
          this.creationError = e.response.data.errors && e.response.data.errors[0] && e.response.data.errors[0].detail ? e.response.data.errors[0].detail : "An error occurred"
          this.loading = false
          console.error("Problem creating playlist", e);
          this.errorMessage = "Problem creating playlist";
        });
      })

    },

    savePlaylist(type) {
      if (type == "success") {
        this.isPlaylistModalOpen = false
        this.loading = true
        this.errorMessage = ""
        this.fetchPlaylists()
      } else {
        this.errorMessage = "Problem editing playlist";
      }
    },

    // reorderPlaylistsDrag() {
    //   this.playlists.forEach((item, index) => {
    //     item.attributes.order = index + 1
    //   })
    //   this.status = 'needsSaving'
    // },

    // reorderPlaylistsInput(newPosition, oldPosition) {
    //   console.log(newPosition, oldPosition)
    //   const playlist = this.playlists.splice(oldPosition, 1)
    //   this.playlists.splice(newPosition, 0, ...playlist)

    //   this.reorderPlaylistsDrag()
    // },

    savePlaylistsOrder() {
      const orderArray = this.playlists.map(element => ({ id: element.id }))

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/order`,
          headers: { "Authorization": jwt },
          data: {
            data: orderArray
          }
        })
        .then(response => {
          this.errorMessage = ""
          console.log("Order saved:", response);
          this.status = "saved"
        })
        .catch(e => {
          console.error("Problem saving playlist order", e);
          this.errorMessage = "Problem saving playlist order";
        });
      })
    },

    editPlaylist(playlist) {
      this.selectedPlaylist = playlist.id
      this.isPlaylistModalOpen = true
    },
    
    deletePlaylist(id) {
      this.loading = true

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "DELETE",
          url: `${this.api}/${id}`,
          headers: { "Authorization": jwt },
        })
        .then(response => {
          this.errorMessage = ""
          console.log("Playlist deleted:", response);
          this.fetchPlaylists()
        })
        .catch(e => {
          this.loading = false;
          console.error("Problem deleting advert", e);
          this.errorMessage = "Problem deleting playlist";
        });
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.playlists {
  position: relative;
  padding: 0;
}

.draggable-item {
  cursor: move;
}

.modal-title {
  padding: 16px;
  margin: 0;
}

.ghost {
  opacity: 0.5;
}

.error-text {
  color: red;
}
</style>
