<template>
  <section class="crest-wrapper mr-5">

    <h3 class="subheading" :class="visible === 'section-crest' ? 'subheading__open' : 'subheading__closed'" @click="togglePanel('section-crest')" ref="section-crest">{{name}} <span class="subheading__label">{{id}}</span></h3>
    <v-icon class="status-icon" v-if="visible === 'section-crest'" color="accent" right @click="togglePanel('section-crest')">remove_circle_outline</v-icon>
    <v-icon class="status-icon" v-else color="accent" right @click="togglePanel('section-crest')">add_circle_outline</v-icon>

    <div class="section-container" v-if="visible === 'section-crest'">
      <!-- <template v-if="crestData"> -->

      <ImageUploader :title="'Default Crest'" :widthOptionEnabled="false"  :mediaLibraryID="crestData ? crestData.crestDefaultMediaLibraryID : null" @update="updateImage($event, 'crestDefault')" />

      <ImageUploader :title="'Light Mode Crest'" :widthOptionEnabled="false"  :mediaLibraryID="crestData ? crestData.crestLightMediaLibraryID : null" @update="updateImage($event, 'crestLight')" />

      <ImageUploader :title="'Dark Mode Crest'" :widthOptionEnabled="false"  :mediaLibraryID="crestData ? crestData.crestDarkMediaLibraryID : null" @update="updateImage($event, 'crestDark')" />

      <!-- </template>
      <p v-else>No Crest Data found.</p> -->
    </div>

  </section>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "TeamCrestOptions",

  components: {
    ImageUploader
  },

  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  mounted() {
    this.getGCTeam(this.id)
  },

  data: () => ({
    api: `${process.env.VUE_APP_TEAMS_API}`,
    crestData: null,
    visible: "",
  }),

  methods: {
    getGCTeam(id) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/teams/${id}?fields=teamCrests`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Crest", response.data.data.attributes.teamCrests)
          this.crestData = response.data.data.attributes.teamCrests
        })
        .catch(e => {
          console.error("Problem getting team data", e);
        });
      })
    },

    updateImage(payload, target) {
      console.log("updating image", payload, target)
      this.crestData[`${target}MediaLibraryID`] = payload.mediaLibraryID
      this.crestData[`${target}Key`] = payload.imageKey

      this.saveGCCrests(this.id)

    },

    saveGCCrests(id) {
      console.log("saving", this.crestData)

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/teams/${id}`,
          headers: { "Authorization": jwt },
          data: {
            "data": {
                "type": "team",
                "id": this.id,
                "attributes": {
                    "teamCrests": this.crestData
                }
            }
          }
        })
        .then(response => {
          console.log("Crests updated", response)
        })
        .catch(e => {
          console.error("Problem saving crest data", e);
        });
      })
    },

    togglePanel(panel) {
      if (panel === this.visible) {
        this.visible = ""
      } else {
        this.visible = panel
      }
    }
  }
}
</script>

<style scoped lang='scss'>

.crest-wrapper {
  width: 100%;
  position: relative;
}

  .subheading-wrapper {
    position: relative;
  }
  .subheading {
    &__closed {
      border-bottom: 1px solid $lightgrey;
      padding-bottom: 12px;
    }
    &:hover {
      cursor: pointer;
    }

    &__label {
      position: relative;
      top: -2px;
      margin: 0 0 0 8px;
      padding: 4px 8px;
      color: #67247C;
      font-size: 12px;
      font-weight: 400;
      background-color: #E6E8F1;
      border-radius: 4px;
    }
  }

  .status-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

</style>
