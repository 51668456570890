<template>

  <section>

    <build-notice mode="full" />

      <section class="section-container crest-module ml-5 mr-5">

      <h3 class="subheading mt-0">Team Crests</h3>

      <template v-for="team in filters" >

        <team-crest-options :name="team.teamName" :id="team.teamID" :key="team.teamID" />

      </template>

    </section>

    <team-crest-search />

      <p class="crest-subheading ml-5 mr-5">This area allows you to specify the preferred crest style in the areas listed.  If a club does not have the relevant crest it will fall back to their default.</p>

    <team-crest-slots />
 
  </section>

</template>

<script>
import axios from "axios"
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice"
import TeamCrestOptions from "@/components/TeamCrests/TeamCrestOptions"
import TeamCrestSearch from "@/components/TeamCrests/TeamCrestSearch"
import TeamCrestSlots from "@/components/TeamCrests/TeamCrestSlots"


export default {
  name: "TeamCrestManager",

  components: {
    BuildNotice,
    TeamCrestOptions,
    TeamCrestSearch,
    TeamCrestSlots
  },

  data: () => ({
    filtersapi: `${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`,
    filters: null,
    crestData: null
  }),

  mounted() {
    this.getFilters()
    // this.getGCTeam()
  },

  methods: {
    getFilters() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.filtersapi}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchFilters: ", response);
          this.filters = response.data.body;

          // for (var team of this.filters) {
          //   console.log('getting crests', team.teamID)
          //   this.getGCTeam(team.teamID)
          // }

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
      })
    }
  }
}
</script>

<style scoped lang='scss'>

.crest-module {
  margin: 16px 0;
}

.crest-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;
}
</style>
