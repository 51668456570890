<template>
  <div class="video__list-item">
    <div class="video__list-item-info">
      <h3 class="video__list-item-heading subheading">Content Feed - {{title}}</h3>
      <small class="video__list-item-type">{{feedID}}</small>
    </div>
    <div class="video__list-item-actions">
      <!-- <button class="video__list-item-actions--delete">Delete</button> -->
      <button class="video__list-item-actions--edit" @click="editFeed()">Edit</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoContentFeedItem",

  props: {
    title: {
      type: String,
      required: true
    },
    feedID: {
      type: String,
      required: true
    },
    isApp: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    editFeed() {
      this.$router.push(`/video-management/content/content-feeds/${this.feedID}?isApp=${this.isApp}`)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
