<template>
  <section>
    <build-notice mode="full" />

    <template v-for="(filterSection, index) in videoFilters">
      <h3 class="subheading" :key="filterSection.type">
        {{ filterSection.label }}
      </h3>
      <div class="section-container" :key="index">
        <p class="notice" v-if="filterSection.type === 'teamPicker'">
          Teams should be added as they appear in the video tags eg: first-team
        </p>
        <p class="notice" v-else-if="filterSection.type === 'seasonPicker'">
          Seasons should be added as full years eg: 2017
        </p>
        <v-layout>
          <v-flex class="half">
            <template v-if="filterSection.type != 'durationPicker'">
              <label class="label">Filter Label</label>
              <v-text-field
                v-model="filterSection.label"
                solo
                flat
              ></v-text-field>
              <label class="label">Search Handler</label>
              <v-text-field
                v-model="filterSection.searchHandler"
                solo
                flat
              ></v-text-field>
              <label class="label">Available Filters</label>
              <draggable
                v-model="filterSection.values"
                :options="{
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                }"
                @start="drag = true"
                @end="
                  drag = false;
                  status = 'needsSaving';
                "
              >
                <transition-group name="list-complete">
                  <filters-card
                    v-for="filter in filterSection.values"
                    :data="filter"
                    :key="filter.id"
                    @deletefilter="deleteFilter($event, filterSection.type)"
                    :allowDisplayName="filterSection.type == 'seasonPicker'"
                    class="draggable-card"
                  />
                </transition-group>
              </draggable>
              <div class="video__filter-button-wrapper">
                <v-btn
                  type="button"
                  depressed
                  @click="addNewFilter(index, filterSection.type)"
                  class="accent"
                >
                  <v-icon small>add</v-icon>
                  Add New {{ displayText(filterSection.type) }} Filter
                </v-btn>
              </div>
            </template>
            <template v-else>
              <label class="label">Filter Label</label>
              <v-text-field
                v-model="filterSection.label"
                solo
                flat
              ></v-text-field>
              <label class="label">Search Handler</label>
              <v-text-field
                v-model="filterSection.searchHandler"
                solo
                flat
              ></v-text-field>
              <label class="label">Available Filters</label>
              <!-- <filters-card-v2 v-for="(filter, subIndex) in filterSection.values" :filterData='filter' :key="filter.id"  @dataChanged="updateFilter($event, index, subIndex)" @deletefilter="deleteFilterProper(index, subIndex)" /> -->
              <draggable
                v-model="filterSection.values"
                :options="{
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                }"
                @start="drag = true"
                @end="
                  drag = false;
                  status = 'needsSaving';
                "
              >
                <transition-group name="list-complete">
                  <filters-card-duration
                    v-for="(filter, subIndex) in filterSection.values"
                    :data="filter"
                    :key="filter.id"
                    @dataChanged="updateFilter($event, index, subIndex)"
                    @deletefilter="deleteFilterProper(index, subIndex)"
                    class="draggable-card"
                  />
                </transition-group>
              </draggable>
              <div class="video__filter-button-wrapper">
                <v-btn
                  type="button"
                  depressed
                  @click="addNewFilter(index, filterSection.type)"
                  class="accent"
                >
                  <v-icon small>add</v-icon>
                  Add New {{ displayText(filterSection.type) }} Filter
                </v-btn>
              </div>
            </template>
          </v-flex>
          <v-flex class="half">
            <!-- <p><i>Help Text</i></p> -->
          </v-flex>
        </v-layout>
      </div>
    </template>
    <v-btn
      v-if="!this.videoFilters.length"
      type="button"
      depressed
      @click="init()"
      class="accent"
    >
      <v-icon small>add</v-icon>
      Initialise filters
    </v-btn>
    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Settings'"
      @buttonClicked="saveFilterData()"
    />
  </section>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice.vue";
import draggable from "vuedraggable";
import FiltersCard from "@/components//VideosManager/Data/VideoFilters/FiltersCard/FiltersCard.vue";
// import FiltersCardV2 from '@/components/VideosManager/Data/VideoFilters/FiltersCard/FiltersCardV2.vue'
import FiltersCardDuration from "@/components/VideosManager/Data/VideoFilters/FiltersCard/FiltersCardDuration.vue";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "MembershipsList",

  components: {
    BuildNotice,
    draggable,
    FiltersCard,
    // FiltersCardV2,
    FiltersCardDuration,
    StickyFooter,
  },

  data: () => ({
    modules: null,
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    configId: null,
    status: null,
    errorMessage: null,
    videoFilters: [],
  }),

  created() {
    this.modules = require("../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    this.getFilterData();
  },

  methods: {
    getFilterData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            // console.log('retrieved filter data :', response.data.data.attributes);
            this.configId = response.data.data.id;
            this.status = "saved";
            this.$store.commit("completeLoading");
            this.videoFilters = response.data.data.attributes.videoFilters
              ? response.data.data.attributes.videoFilters
              : [];
          })
          .catch((e) => {
            console.error("Problem getting filter data", e);
            this.errorMessage = "Problem Getting Filter Data";
            this.$store.commit("completeLoading");
          });
      });
    },

    saveFilterData() {
      const data = {
        data: {
          type: this.modules.default.Streamline
            ? "streamline-config"
            : "video-global-config",
          attributes: {
            videoFilters: this.videoFilters,
          },
        },
      };

      if (this.modules.default.Streamline) {
        data.data.id = this.configId;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: this.modules.default.Streamline
            ? `${this.streamlineApi}/config`
            : `${this.videosApi}/config/${this.configId}`,
          headers: { Authorization: jwt },
          data,
        })
          .then((response) => {
            console.log("saved filter data :", response.data.data.attributes);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving filter data", e);
          });
      });
    },

    addNewFilter(key, type) {
      console.log(key, type);
      if (type == "durationPicker") {
        this.videoFilters[key].values.push({
          name: "",
          label: "",
          id: this.$uuid.v1(),
          videoDurationMin: null,
          videoDurationMax: null,
        });
      } else if (
        type == "teamPicker" &&
        (this.modules.default.LFCExclusive ||
          this.modules.default.StreamlineFiltersv2)
      ) {
        this.videoFilters[key].values.push({
          name: "",
          teamID: "",
          id: this.$uuid.v1(),
        });
      } else {
        this.videoFilters[key].values.push({
          name: "",
          displayName: "",
          id: this.$uuid.v1(),
        });
      }
      this.status = "needsSaving";
    },

    deleteFilter(filter, type) {
      // I seem to have lost my mind
      const index = this.videoFilters.findIndex(
        (element) => element.type === type
      );
      this.videoFilters[index].values = this.videoFilters[index].values.filter(
        (item) => item !== filter
      );
    },

    deleteFilterProper(key, subKey) {
      this.videoFilters[key].values.splice(subKey, 1);
    },

    updateFilter(newData, key, subKey) {
      //For card v2
      this.status = "needsSaving";
      this.videoFilters[key].values[subKey] = newData;
    },

    displayText(key) {
      if (key === "teamPicker") {
        return "team";
      } else if (key === "seasonPicker") {
        return "season";
      } else if (key === "durationPicker") {
        return "duration";
      }
    },

    init() {
      this.videoFilters = [
        {
          label: "Teams",
          type: "teamPicker",
          values: [],
        },
        {
          label: "Season",
          type: "seasonPicker",
          values: [],
        },
        {
          label: "Duration",
          type: "durationPicker",
          values: [],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-card {
  cursor: grab;
}
</style>