<template>
  <div>
    <build-notice mode="full" />
    <h3 class="subheading">Browse Page Data</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageUploader
            :title="'Browse Hero Background Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              updatedData.browseHeroBackgroundImageMediaLibraryID
            "
            @update="
              updateImage(
                $event,
                'browseHeroBackgroundImageMediaLibraryID',
                'browseHeroBackgroundImageKey'
              )
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>This will set the background image in the browse section.</i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Browse Hero Mode</label>
          <v-select
            v-model="updatedData.browseHeroMode"
            solo
            flat
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Use light mode for the hero', value: 'light' },
              { name: 'Use dark mode for the hero', value: 'dark' },
            ]"
            @change="updateStatus()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the mode for the browse hero.</i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="field">Browse Hero Title</label>
          <v-text-field
            id="field"
            v-model="updatedData.browseHeroTitle"
            solo
            flat
            required
            @input="updateStatus()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will set the title for the browse hero.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Settings'"
      @buttonClicked="saveBrowseSettings()"
    />
  </div>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components//Library/BuildNotice/BuildNotice.vue";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "VideosBrowseSettings",

  components: {
    BuildNotice,
    ImageUploader,
    StickyFooter,
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    configId: null,
    status: null,
    errorMessage: null,
    updatedData: {
      browseHeroBackgroundImageKey: null,
      browseHeroBackgroundImageMediaLibraryID: null,
      browseHeroMode: null,
      browseHeroTitle: null,
    },
  }),

  created() {
    this.modules = require("../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    this.getBrowseSettings();
  },

  methods: {
    getBrowseSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log(
              "retrieved browse settings:",
              response.data.data.attributes
            );
            this.updatedData = response.data.data.attributes;
            this.configId = response.data.data.id;
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem getting browse settings", e);
            // this.status = 'needsSaving'
            this.errorMessage = "Problem getting browse settings";
          });
      });
    },

    saveBrowseSettings() {
      const data = {
        data: {
          type: this.modules.default.Streamline
            ? "streamline-config"
            : "video-global-config",
          attributes: {
            browseHeroBackgroundImageKey: this.updatedData
              .browseHeroBackgroundImageKey
              ? this.updatedData.browseHeroBackgroundImageKey
              : "",
            browseHeroBackgroundImageMediaLibraryID: this.updatedData
              .browseHeroBackgroundImageMediaLibraryID
              ? this.updatedData.browseHeroBackgroundImageMediaLibraryID
              : "",
            browseHeroMode: this.updatedData.browseHeroMode
              ? this.updatedData.browseHeroMode
              : "",
            browseHeroTitle: this.updatedData.browseHeroTitle
              ? this.updatedData.browseHeroTitle
              : "",
          },
        },
      };

      if (this.modules.default.Streamline) {
        data.data.id = this.configId;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: this.modules.default.Streamline
            ? `${this.streamlineApi}/config`
            : `${this.videosApi}/config/${this.configId}`,
          headers: { Authorization: jwt },
          data,
        })
          .then((response) => {
            console.log("saved browse settings:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving browse settings", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving browse settings";
          });
      });
    },

    updateImage(payload, id, key) {
      this.status = "needsSaving";
      this.updatedData[id] = payload.mediaLibraryID;
      this.updatedData[key] = payload.imageKey;

      if (payload.mediaLibraryID) {
        this.updatedData[id] = payload.mediaLibraryID;
        this.updatedData[key] = payload.imageKey;
      } else {
        this.updatedData[id] = null;
        this.updatedData[key] = null;
      }
    },

    updateStatus() {
      this.status = "needsSaving";
    },
  },
};
</script>

<style scoped lang="scss">
.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}
</style>
