<template>
  <v-dialog class="modal" v-model="dialog" persistent width="1000" lazy>
    <template>
      <v-card>
        <template v-if="!loading">
          <v-card-title class="title">Playlist details</v-card-title>

          <v-card-text>
            <label class="label" for="playlistTitle"
              >Title
              <span v-if="data.title.match(/(\[HIDE\]\s*)/g)"
                >- This title will be hidden on the playlist card</span
              ></label
            >
            <v-text-field
              id="playlistTitle"
              placeholder=""
              solo
              flat
              v-model="data.title"
            ></v-text-field>

            <ImageUploader
              :title="'Playlist Image'"
              :widthOptionEnabled="false"
              :mediaLibraryID="data.imageData.mediaLibraryID"
              @update="updateImage($event)"
              :desc="'<p>Recommended size: 352px width by 198px height or a similar 16:9 ratio</p>'"
            />

            <label class="label" for="playlistPublished"
              >Published Status</label
            >
            <v-select
              id="playlistPublished"
              v-model="data.publicationData.published"
              item-text="name"
              item-value="value"
              :items="[
                {
                  name: 'Draft',
                  value: 0,
                },
                {
                  name: 'Published',
                  value: 1,
                },
              ]"
              solo
              flat
            />

            <div class="videos-selection">
              <div class="videos-top">
                <label class="videos-label subheading"
                  >Videos - {{ videoData ? videoData.length : 0 }} Video{{
                    videoData.length !== 1 ? "s" : ""
                  }}</label
                >
                <v-dialog
                  v-model="isVideoSelectorOpen"
                  width="1400"
                  lazy
                  class="fixture-details"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="add-video-btn"
                      color="accent"
                      type="button"
                      v-bind="attrs"
                      @on="on"
                      @click="isVideoSelectorOpen = true"
                      >Add video</v-btn
                    >
                  </template>

                  <v-card v-if="isVideoSelectorOpen" class="card">
                    <video-listing
                      :alternativeMode="'selection'"
                      @selected="addVideo($event)"
                      @unselected="removeVideo($event, true)"
                      :selectedVideos="videoData.map((el) => el.id)"
                    />
                  </v-card>
                </v-dialog>
              </div>
              <ul class="video-list">
                <draggable
                  v-if="videoData.length"
                  v-model="videoData"
                  :options="{
                    ghostClass: 'ghost',
                    dragClass: 'sortable-drag',
                  }"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group
                    name="list-complete"
                    class="featured__items"
                  >
                    <li
                      v-for="video in videoData"
                      :key="video.id"
                      class="video"
                    >
                      <template
                        v-if="
                          video.attributes.imageData &&
                          video.attributes.imageData.landscape &&
                          video.attributes.imageData.landscape.imageKey
                        "
                      >
                        <template v-if="video.attributes.imageData">
                          <img
                            v-if="video.attributes.imageData.landscape.imageKey"
                            :src="`${imageDomain}/160x90/${video.attributes.imageData.landscape.imageKey}`"
                            class="video-listing-item__image"
                          />
                        </template>
                      </template>
                      <template v-else-if="video.attributes.assetData">
                        <img
                          v-if="
                            video.attributes.assetData.playbackIds[0].policy ===
                            'signed'
                          "
                          :src="`https://image.mux.com/${video.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?token=${video.attributes.assetData.thumbnailToken}&width=400&height=200`"
                          class="video-image"
                        />
                        <img
                          v-else
                          :src="`https://image.mux.com/${video.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?width=400&height=200`"
                          class="video-image"
                        />
                      </template>

                      <div class="video-data">
                        <h3 class="video-title">
                          {{ video.attributes.title }}
                        </h3>
                        <div class="video-buttons">
                          <div>
                            <v-icon class="draggable-item__handle-icon"
                              >open_with</v-icon
                            >
                            <v-icon
                              @click="removeVideo(video.id, false)"
                              class="video-delete"
                              color="accent"
                              right
                              >remove_circle</v-icon
                            >
                          </div>
                          <!-- <input 
                                                    min="1"
                                                    :max="data.videos.length"
                                                    id="video-position-input" 
                                                    class="video-position-input" 
                                                    type="number" 
                                                    solo 
                                                    flat 
                                                    v-model="video.position" 
                                                    @change="reorderVideosInput($event, video.position)"/> -->
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
              </ul>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outline flat @click="cancel()"> Cancel </v-btn>

            <v-btn
              color="accent"
              :class="{ success: savePlaylistStatus == 'Saved!' }"
              outline
              flat
              @click="savePlaylist()"
            >
              {{ savePlaylistStatus }}
            </v-btn>
          </v-card-actions>
        </template>
        <p v-else class="loading">Loading...</p>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import VideoListing from "@/components/Streamline/VideoListing.vue";
import ImageUploader from "@/components/Library/ImageUploader";

export default {
  name: "VideosPlaylistModal",

  props: {
    playlistID: {
      type: String,
      required: true,
    },
  },

  components: {
    ImageUploader,
    VideoListing,
    draggable,
  },

  data: () => ({
    api: `${process.env.VUE_APP_STREAMLINEAPI}/playlists`,
    dialog: true,
    loading: true,
    isVideoSelectorOpen: false,
    imageDomain: process.env.VUE_APP_IMAGEURL,
    data: {
      title: "",
      image: "",
      status: false,
    },
    videoData: [],
    savePlaylistStatus: "Save PLaylist",
  }),

  created() {
    this.fetchPlaylist();
  },

  // watch: {
  //     playlist() {
  //         if (this.playlist) {
  //             this.dialog = true
  //             this.data = JSON.parse(JSON.stringify(this.playlist))
  //             if (this.videoObjects) {
  //                 this.videoDisplayData = JSON.parse(JSON.stringify(this.videoObjects))
  //             } else {
  //                 this.videoDisplayData = []
  //             }
  //         } else {
  //             this.dialog = false
  //         }
  //     },
  // },

  methods: {
    fetchPlaylist() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/${this.playlistID}`,
          headers: { "Content-Type": "application/json", Authorization: jwt },
        })
          .then((response) => {
            console.log("Playlist response:", response);
            this.data = response.data.data.attributes;
            if (
              response.data.data.relationships &&
              response.data.data.relationships.videos
            ) {
              this.videoData =
                response.data.data.relationships &&
                response.data.data.relationships.videos
                  ? response.data.data.relationships.videos.data
                  : [];
            } else {
              this.videoData = [];
            }
            this.loading = false;
          })
          .catch((e) => {
            console.error("Problem fetching playlist", e);
          });
      });
    },

    cancel() {
      this.$emit("closeModal");
    },

    addVideo(video) {
      this.videoData.push({
        id: video.id,
        attributes: {
          title: video.attributes.title,
          imageData: video.attributes.imageData,
          assetData: video.attributes.assetData,
        },
        // position: this.data.videos.length + 1
      });
      this.$forceUpdate();
    },

    removeVideo(id, bypass) {
      if (bypass || confirm("Are you sure you want to remove this item?")) {
        this.videoData = this.videoData.filter((element) => element.id !== id);
      }
      this.$forceUpdate();
      // this.reorderVideosDrag()
    },

    updateImage(image) {
      this.data.imageData = image;
    },

    savePlaylist() {
      const videos = this.videoData.map((video) => video.id);

      const attributes = {
        title: this.data.title,
        imageData: this.data.imageData,
        publicationData: {
          published: this.data.publicationData.published,
        },
        videos: videos,
      };

      this.savePlaylistStatus = "Saving...";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/${this.playlistID}`,
          headers: { Authorization: jwt },
          data: {
            data: {
              attributes,
            },
          },
        })
          .then((response) => {
            console.log("Playlist edited:", response);
            this.savePlaylistStatus = "Saved!";
            setTimeout(() => {
              this.$emit("savePlaylist", "success");
            }, 1000);
          })
          .catch((e) => {
            this.savePlaylistStatus = "An error occurred";
            console.error("Problem editing playlist", e);
            this.$emit("savePlaylist", "failure");
          });
      });
    },

    // reorderVideosDrag() {
    //     this.data.videos.forEach((item, index) => {
    //         item.position = index + 1
    //     })
    // },

    // reorderVideosInput(newPosition, oldPosition) {
    //     console.log('inside reorder', newPosition, oldPosition)
    //     const video = this.data.videos.splice(oldPosition - 1, 1)
    //     this.data.videos.splice(newPosition - 1, 0, ...video)

    //     this.reorderVideosDrag()
    // },
  },
};
</script>

<style scoped lang='scss'>
.title {
  font-weight: bold;
  color: #24002f;
}

.videos-selection {
  background: #e6e8f1;
  padding: 10px;
  overflow: hidden;
}

.videos-top {
  display: flex;
  align-items: center;
}

.videos-label {
  margin-right: auto;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.add-video-btn {
  margin-left: 10px;
}

.video-list {
  padding-left: 0;
}

.video {
  display: flex;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  background: #f8f8f8;
  margin-top: 10px;
  cursor: move;
}

.video-data {
  padding: 10px;
  display: flex;
  flex: 1;
}

.video-title {
  font-weight: normal;
  margin-right: 10px;
}

.video-image {
  height: 100%;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.video-buttons {
  margin-left: auto;
  align-self: center;
  min-width: 64px;
}

.video-position-input {
  margin-top: 8px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #dfe3e9;
  padding: 3px;
  margin-left: auto;
}

.success {
  color: white !important;
  background-color: rgb(43, 194, 83) !important;
}

.loading {
  text-align: center;
  padding: 10px;
}
</style>
