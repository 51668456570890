<template>
  <div>
    <build-notice mode="full" />
    <h3 class="subheading">StreamAMG Settings</h3>
    <section v-if="renderStreamDetails" class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cloudMatrixAPI">Cloud Matrix API</label>
          <v-text-field
            id="cloudMatrixAPI"
            v-model="updatedData.cloudMatrixAPI"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cloudPayDomain">Cloud Pay Domain</label>
          <v-text-field
            id="cloudPayDomain"
            v-model="updatedData.cloudPayDomain"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cloudPayTickets">Cloud Pay Tickets</label>
          <v-text-field
            id="cloudPayTickets"
            v-model="updatedData.cloudPayTickets"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="streamPlayFeed">Stream Play Feed</label>
          <v-text-field
            id="streamPlayFeed"
            v-model="updatedData.streamPlayFeed"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="partnerID">Partner ID</label>
          <v-text-field
            id="partnerID"
            v-model="updatedData.partnerID"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="webVideoOnDemandUiConf"
            >Web Video On Demand UiConf</label
          >
          <v-text-field
            id="webVideoOnDemandUiConf"
            v-model="updatedData.webVideoOnDemandUiConf"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="webLiveVideoUiConf"
            >Web Live Video UiConf</label
          >
          <v-text-field
            id="webLiveVideoUiConf"
            v-model="updatedData.webLiveVideoUiConf"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="webLiveAudioUiConf"
            >Web Live Audio UiConf</label
          >
          <v-text-field
            id="webLiveAudioUiConf"
            v-model="updatedData.webLiveAudioUiConf"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="webLiveVideoEntryID"
            >Web Live Video EntryID</label
          >
          <v-text-field
            id="webLiveVideoEntryID"
            v-model="updatedData.webLiveVideoEntryID"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="webLiveAudioEntryID"
            >Web Live Audio EntryID</label
          >
          <v-text-field
            id="webLiveAudioEntryID"
            v-model="updatedData.webLiveAudioEntryID"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="categoryKey">Category Key</label>
          <v-text-field
            id="categoryKey"
            v-model="updatedData.categoryKey"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="emptyEntitlementFallback"
            >Empty Entitlement Fallback</label
          >
          <v-text-field
            id="emptyEntitlementFallback"
            v-model="updatedData.emptyEntitlementFallback"
            solo
            flat
            required
            @input="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i> </i></p>
        </v-flex>
      </v-layout>

      <StickyFooter
        :errorMessage="errorMessage"
        :status="status"
        :buttonText="'Save Settings'"
        @buttonClicked="saveStreamSettings()"
      />
    </section>
  </div>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice.vue";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "StreamIntegration",

  components: {
    BuildNotice,
    StickyFooter,
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    status: null,
    errorMessage: null,
    updatedData: null,
    renderStreamDetails: null,
  }),

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    this.getStreamSettings();
  },

  methods: {
    getStreamSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log(
              "retrieved stream integration settings:",
              response.data.data.attributes.streamAMG
            );
            this.updatedData = response.data.data.attributes.streamAMG;
            this.configId = response.data.data.id;
            this.renderStreamDetails = true;
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem getting stream integration settings", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem getting stream integration settings";
          });
      });
    },

    saveStreamSettings() {
      const data = {
        data: {
          type: this.modules.default.Streamline
            ? "streamline-config"
            : "video-global-config",
          attributes: {
            streamAMG: {
              cloudMatrixAPI: this.updatedData.cloudMatrixAPI
                ? this.updatedData.cloudMatrixAPI
                : "",
              cloudPayDomain: this.updatedData.cloudPayDomain
                ? this.updatedData.cloudPayDomain
                : "",
              partnerID: this.updatedData.partnerID
                ? this.updatedData.partnerID
                : "",
              webLiveVideoUiConf: this.updatedData.webLiveVideoUiConf
                ? this.updatedData.webLiveVideoUiConf
                : "",
              webLiveAudioUiConf: this.updatedData.webLiveAudioUiConf
                ? this.updatedData.webLiveAudioUiConf
                : "",
              webLiveVideoEntryID: this.updatedData.webLiveVideoEntryID
                ? this.updatedData.webLiveVideoEntryID
                : "",
              webLiveAudioEntryID: this.updatedData.webLiveAudioEntryID
                ? this.updatedData.webLiveAudioEntryID
                : "",
              webVideoOnDemandUiConf: this.updatedData.webVideoOnDemandUiConf
                ? this.updatedData.webVideoOnDemandUiConf
                : "",
              cloudPayTickets: this.updatedData.cloudPayTickets
                ? this.updatedData.cloudPayTickets
                : "",
              streamPlayFeed: this.updatedData.streamPlayFeed
                ? this.updatedData.streamPlayFeed
                : "",
              categoryKey: this.updatedData.categoryKey
                ? this.updatedData.categoryKey
                : "",
              emptyEntitlementFallback: this.updatedData
                .emptyEntitlementFallback
                ? this.updatedData.emptyEntitlementFallback
                : "",
            },
          },
        },
      };

      if (this.modules.default.Streamline) {
        data.data.id = this.configId;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: this.modules.default.Streamline
            ? `${this.streamlineApi}/config`
            : `${this.videosApi}/config/${this.configId}`,
          headers: { Authorization: jwt },
          data,
        })
          .then((response) => {
            console.log("saved settings:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving global settings", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving global settings";
          });
      });
    },

    updateData() {
      this.status = "needsSaving";
    },
  },
};
</script>

<style>
</style>