var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('build-notice',{attrs:{"mode":"full"}}),_vm._l((_vm.videoFilters),function(filterSection,index){return [_c('h3',{key:filterSection.type,staticClass:"subheading"},[_vm._v(" "+_vm._s(filterSection.label)+" ")]),_c('div',{key:index,staticClass:"section-container"},[(filterSection.type === 'teamPicker')?_c('p',{staticClass:"notice"},[_vm._v(" Teams should be added as they appear in the video tags eg: first-team ")]):(filterSection.type === 'seasonPicker')?_c('p',{staticClass:"notice"},[_vm._v(" Seasons should be added as full years eg: 2017 ")]):_vm._e(),_c('v-layout',[_c('v-flex',{staticClass:"half"},[(filterSection.type != 'durationPicker')?[_c('label',{staticClass:"label"},[_vm._v("Filter Label")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(filterSection.label),callback:function ($$v) {_vm.$set(filterSection, "label", $$v)},expression:"filterSection.label"}}),_c('label',{staticClass:"label"},[_vm._v("Search Handler")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(filterSection.searchHandler),callback:function ($$v) {_vm.$set(filterSection, "searchHandler", $$v)},expression:"filterSection.searchHandler"}}),_c('label',{staticClass:"label"},[_vm._v("Available Filters")]),_c('draggable',{attrs:{"options":{
                ghostClass: 'ghost',
                dragClass: 'sortable-drag',
              }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false;
                _vm.status = 'needsSaving';}},model:{value:(filterSection.values),callback:function ($$v) {_vm.$set(filterSection, "values", $$v)},expression:"filterSection.values"}},[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((filterSection.values),function(filter){return _c('filters-card',{key:filter.id,staticClass:"draggable-card",attrs:{"data":filter,"allowDisplayName":filterSection.type == 'seasonPicker'},on:{"deletefilter":function($event){return _vm.deleteFilter($event, filterSection.type)}}})}),1)],1),_c('div',{staticClass:"video__filter-button-wrapper"},[_c('v-btn',{staticClass:"accent",attrs:{"type":"button","depressed":""},on:{"click":function($event){return _vm.addNewFilter(index, filterSection.type)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")]),_vm._v(" Add New "+_vm._s(_vm.displayText(filterSection.type))+" Filter ")],1)],1)]:[_c('label',{staticClass:"label"},[_vm._v("Filter Label")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(filterSection.label),callback:function ($$v) {_vm.$set(filterSection, "label", $$v)},expression:"filterSection.label"}}),_c('label',{staticClass:"label"},[_vm._v("Search Handler")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(filterSection.searchHandler),callback:function ($$v) {_vm.$set(filterSection, "searchHandler", $$v)},expression:"filterSection.searchHandler"}}),_c('label',{staticClass:"label"},[_vm._v("Available Filters")]),_c('draggable',{attrs:{"options":{
                ghostClass: 'ghost',
                dragClass: 'sortable-drag',
              }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false;
                _vm.status = 'needsSaving';}},model:{value:(filterSection.values),callback:function ($$v) {_vm.$set(filterSection, "values", $$v)},expression:"filterSection.values"}},[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((filterSection.values),function(filter,subIndex){return _c('filters-card-duration',{key:filter.id,staticClass:"draggable-card",attrs:{"data":filter},on:{"dataChanged":function($event){return _vm.updateFilter($event, index, subIndex)},"deletefilter":function($event){return _vm.deleteFilterProper(index, subIndex)}}})}),1)],1),_c('div',{staticClass:"video__filter-button-wrapper"},[_c('v-btn',{staticClass:"accent",attrs:{"type":"button","depressed":""},on:{"click":function($event){return _vm.addNewFilter(index, filterSection.type)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")]),_vm._v(" Add New "+_vm._s(_vm.displayText(filterSection.type))+" Filter ")],1)],1)]],2),_c('v-flex',{staticClass:"half"})],1)],1)]}),(!this.videoFilters.length)?_c('v-btn',{staticClass:"accent",attrs:{"type":"button","depressed":""},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")]),_vm._v(" Initialise filters ")],1):_vm._e(),_c('StickyFooter',{attrs:{"errorMessage":_vm.errorMessage,"status":_vm.status,"buttonText":'Save Settings'},on:{"buttonClicked":function($event){return _vm.saveFilterData()}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }