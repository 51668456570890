<template>
    <div>
        <build-notice mode="full" />
        <h3 class="subheading">Streamline Settings</h3>
        <section
            class="section-container"
            v-if="renderStreamlineDetails"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="muxDataEnvironmentKey"
                    >Mux Data Environment Key</label>
                    <v-text-field
                        id="muxDataEnvironmentKey"
                        v-model="updatedData.muxDataEnvironmentKey"
                        solo
                        flat
                        required
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i> </i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="videoAPI"
                    >Streamline API</label>
                    <v-text-field
                        id="videoAPI"
                        v-model="updatedData.videoAPI"
                        solo
                        flat
                        required
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i> </i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    mb-3
                    class="half"
                >
                    <label
                        class="label"
                        for="webDefaultLiveVideoAssetID"
                    >Web - Default Video Asset</label>

                    <live-asset-picker
                        :assetID="updatedData.webDefaultLiveVideoAssetID"
                        @assetUpdated="updateVideoAsset($event)"
                        @assetCleared="clearVideoAsset()"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i> </i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="webDefaultLiveAudioAssetID"
                    >Web - Default Audio Asset</label>

                    <live-asset-picker
                        :assetID="updatedData.webDefaultLiveAudioAssetID"
                        @assetUpdated="updateAudioAsset($event)"
                        @assetCleared="clearAudioAsset()"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i> </i></p>
                </v-flex>
            </v-layout>
            <v-layout
                row
                v-if="this.modules.default.StreamlineAutoSubtitles"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="webDefaultLiveAudioAssetID"
                    >Enable Automatic Subtitles</label>

                    <v-switch
                        v-model="enableAutoSubs"
                        @change="updateData()"
                    >
                    </v-switch>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i> </i></p>
                </v-flex>
            </v-layout>

        </section>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Settings'"
            @buttonClicked="saveStreamlineSettings()"
        />
    </div>
</template>

<script>
import axios from "axios";
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice.vue";
import StickyFooter from "@/components/Library/StickyFooter";
import LiveAssetPicker from "@/components/Library/LiveAssetPicker";

export default {
    name: "StreamlineIntegration",

    components: {
        BuildNotice,
        StickyFooter,
        LiveAssetPicker,
    },

    data: () => ({
        videosApi: process.env.VUE_APP_VIDEOS_API,
        streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
        modules: null,
        status: null,
        errorMessage: null,
        updatedData: null,
        renderStreamlineDetails: null,
        enableAutoSubs: null,
    }),

    created() {
        this.modules = require("../../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    mounted() {
        this.getStreamlineSettings();
    },

    methods: {
        getStreamlineSettings() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.modules.default.Streamline
                        ? this.streamlineApi
                        : this.videosApi
                        }/config`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log(
                            "retrieved streamline integration settings:",
                            response.data.data.attributes.uzStreamline
                        );
                        this.updatedData = response.data.data.attributes.uzStreamline
                            ? response.data.data.attributes.uzStreamline
                            : {};
                        this.enableAutoSubs = response.data.data.attributes.enableAutoSubs
                        this.configId = response.data.data.id;
                        this.renderStreamlineDetails = true;
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem getting streamline integration settings", e);
                        this.status = "needsSaving";
                        this.errorMessage =
                            "Problem getting streamline integration settings";
                    });
            });
        },

        saveStreamlineSettings() {
            const data = {
                data: {
                    type: this.modules.default.Streamline
                        ? "streamline-config"
                        : "video-global-config",
                    attributes: {
                        uzStreamline: {
                            muxDataEnvironmentKey: this.updatedData.muxDataEnvironmentKey
                                ? this.updatedData.muxDataEnvironmentKey
                                : "",
                            videoAPI: this.updatedData.videoAPI
                                ? this.updatedData.videoAPI
                                : "",
                            webDefaultLiveVideoAssetID: this.updatedData
                                .webDefaultLiveVideoAssetID
                                ? this.updatedData.webDefaultLiveVideoAssetID
                                : "",
                            webDefaultLiveAudioAssetID: this.updatedData
                                .webDefaultLiveAudioAssetID
                                ? this.updatedData.webDefaultLiveAudioAssetID
                                : "",
                        },
                        enableAutoSubs: this.enableAutoSubs
                    },
                },
            };

            if (this.modules.default.Streamline) {
                data.data.id = this.configId;
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: this.modules.default.Streamline
                        ? `${this.streamlineApi}/config`
                        : `${this.videosApi}/config/${this.configId}`,
                    headers: { Authorization: jwt },
                    data,
                })
                    .then((response) => {
                        console.log("saved settings:", response);
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving global settings", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving global settings";
                    });
            });
        },

        updateAudioAsset(payload) {
            this.updatedData.webDefaultLiveAudioAssetID = payload.id;
            this.updateData();
        },

        clearAudioAsset() {
            this.updatedData.webDefaultLiveAudioAssetID = "";
            this.updateData();
        },

        updateVideoAsset(payload) {
            this.updatedData.webDefaultLiveVideoAssetID = payload.id;
            this.updateData();
        },

        clearVideoAsset() {
            this.updatedData.webDefaultLiveVideoAssetID = "";
            this.updateData();
        },

        updateData() {
            this.status = "needsSaving";
        },
    },
};
</script>

<style
    scoped
    lang='scss'
>
.half {
    width: 50%;
}

.third-x2 {
    width: 66.66%;
}

.third {
    width: 33.33%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}
</style>
